// Profiles Nearby
.profilesnearby {

  // Collection
  .profilesnearby__collection {
    font-family: 'Matter',sans-serif;

    // Single
    .profilesnearby__collection__single {
      margin: 0px auto;
      padding: 20px 0;
      border-bottom: solid 1px #aaaaaa;
      @media($xs-up) {
        width: 100%;
      }
      @media($md-up) {
        padding: 40px 0;
      }

      // Modifier: compact
      &.profilesnearby__collection__single--compact {
        @media($xs-up) {
          width: 100%;
        }
        @media($md-up) {
          width: 100%;
          padding: 20px 0;
        }
        &:first-of-type {
          margin-top: 5px;
        }
        &:last-of-type {
          border: none;
        }
      }
    }
  }

}
