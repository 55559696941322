
// commmon styles
.text-size-small {
  font-size: .875rem;
  line-height: 1.57;
}

.text-color-neutral-400 {
  color: #a7a7a7;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.text-size-tiny {
  font-size: .75rem;
}

.no-underline {
  text-decoration: none;
}

.text-white {
  color: #ffffff;
}

.footer_link {
  color: #a7a7a7;
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1.75;
  transition: color .3s cubic-bezier(.165,.84,.44,1);
  text-decoration: none;
}
.footer_link:hover {
  color: #90E41E;
}



.footer_component {
  background-color: $color-black;
  color: $color-white;
  padding-top: 5rem;
  padding-bottom: 3.5rem;

  .padding-global {
    padding-left: 5%;
    padding-right: 5%;

    .container-large {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      .footer_wrap {
        .footer_top {
          padding-bottom: 1.5rem;
          .footer_grid {
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            grid-template-rows: auto;
            grid-template-columns: .4fr 1fr;
            grid-auto-columns: 1fr;
            display: grid;

            .footer_company {
              grid-column-gap: 2rem;
              grid-row-gap: 2rem;
              flex-flow: column;
              justify-content: space-between;
              align-items: flex-start;
              display: flex;

              .footer_logo-icon {
                width: 8.25rem;
              }
              
              .footer_company-bottom {
                grid-column-gap: 2rem;
                grid-row-gap: 2rem;
                flex-flow: column;
                flex: 1;
                display: flex;

                .footer_rating-wrap {
                  grid-column-gap: .75rem;
                  grid-row-gap: .75rem;
                  flex-flow: column;
                  display: flex;

                  .footer_rating-icon {
                    color: #ffc021;
                    width: 100%;
                    max-width: 7.25rem;
                  }
                }
                .footer_logo-list {
                  grid-column-gap: 1rem;
                  grid-row-gap: 1rem;
                  display: flex;
                  .footer_logo-wrap {
                    width: 2.75rem;
                    height: 2.75rem;

                    img {
                      width: 100%;
                      max-width: 100%;
                      height: auto;
                      display: inline-block;
                    }
                  }
                }
                .login-link-footer {
                  margin-top: 6px;
                  font-size: .8125rem;
                  line-height: 1.57;
                  margin-top: 7px;
                }
              }
            }
            .footer_links-grid {
              grid-column-gap: 1rem;
              grid-row-gap: 1rem;
              grid-template-rows: auto;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              grid-auto-columns: 1fr;
              padding-top: 1.2rem;
              display: grid;
              
              .footer_links-col {
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
                display: flex;
                .footer_links-title {
                  padding-bottom: 1rem;
                  font-weight: 500;
                  line-height: 1.75;
                }
              }
            }
          }
        }
        .footer_bottom {
          border-top: 1px solid #ffffff29;
          padding-top: 1.5rem;

          .footer_bottom-grid {
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            grid-template-rows: auto;
            grid-template-columns: .35fr .85fr;
            grid-auto-columns: 1fr;
            display: grid;

            .footer_copyright {
              color: #ffffffa3;
              font-size: .8125rem;
              line-height: 1.57;
            }

            .footer_bottom-right {
              grid-column-gap: 2rem;
              grid-row-gap: 2rem;
              flex-flow: wrap;
              justify-content: space-between;
              align-items: flex-start;
              display: flex;
              font-size: .8125rem;

              .footer_legal-wrap {
                grid-column-gap: 1.5rem;
                grid-row-gap: 1.5rem;
                justify-content: flex-start;
                align-items: center;
                display: flex;

                @media ($lg-up) {
                  margin-left: 20%;
                }

                .footer_legal-link {
                  color: #a7a7a7;
                  line-height: 1.75;
                  transition: color .3s cubic-bezier(.165,.84,.44,1);
                  text-decoration: none;
                }
                .footer_legal-link:hover {  
                  color: #90E41E;
                }
              }

              .footer_social-wrap {
                grid-column-gap: .75rem;
                grid-row-gap: .75rem;
                justify-content: space-between;
                align-items: flex-end;
                display: flex;

                .footer_social-link {
                  color: var(--neutral--white);
                  transition: color .3s cubic-bezier(.165,.84,.44,1);

                  .icon-embed-xsmall {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 1.5rem;
                    height: 1.5rem;
                    display: flex;
                  }
                }
                .footer_social-link:hover {  
                  color: #90E41E;
                }
              }
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width: 991px) {
  // commmon styles
  .text-size-small {
    font-size: .875rem;
    line-height: 1.57;
  }

  .text-color-neutral-400 {
    color: #a7a7a7;
  }

  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }

  .text-size-tiny {
    font-size: .75rem;
  }

  .no-underline {
    text-decoration: none;
  }

  .text-white {
    color: #ffffff;
  }

  .footer_link {
    color: #a7a7a7;
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    line-height: 1.75;
    transition: color .3s cubic-bezier(.165,.84,.44,1);
    text-decoration: none;
  }
  .footer_link:hover {
    color: #90E41E;
  }

  .footer_component {
    background-color: $color-black;
    color: $color-white;
    padding-top: 5rem;
    padding-bottom: 3.5rem;

    .padding-global {
      padding-left: 5%;
      padding-right: 5%;

      .container-large {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .footer_wrap {
          .footer_top {
            padding-bottom: 1.5rem;
            .footer_grid {
              grid-column-gap: 2rem;
              grid-row-gap: 2rem;
              grid-template-rows: auto;
              grid-template-columns: .4fr 1fr;
              grid-auto-columns: 1fr;
              display: grid;

              .footer_company {
                justify-content: flex-start;

                .footer_logo-icon {
                  width: 8.25rem;
                }
                
                .footer_company-bottom {
                  grid-column-gap: 2rem;
                  grid-row-gap: 2rem;
                  flex-flow: column;
                  flex: 1;
                  display: flex;

                  .footer_rating-wrap {
                    grid-column-gap: .75rem;
                    grid-row-gap: .75rem;
                    flex-flow: column;
                    display: flex;

                    .footer_rating-icon {
                      color: #ffc021;
                      width: 100%;
                      max-width: 7.25rem;
                    }
                  }
                  .footer_logo-list {
                    grid-column-gap: 1rem;
                    grid-row-gap: 1rem;
                    display: flex;
                    .footer_logo-wrap {
                      width: 2.75rem;
                      height: 2.75rem;

                      img {
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                        display: inline-block;
                      }
                    }
                  }
                }
              }
              .footer_links-grid {
                grid-template-columns: 1fr 1fr;
                
                .footer_links-col {
                  flex-flow: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  display: flex;
                  .footer_links-title {
                    padding-bottom: 1rem;
                    font-weight: 500;
                    line-height: 1.75;
                  }
                }
              }
            }
          }
          .footer_bottom {
            border-top: 1px solid #ffffff29;
            padding-top: 1.5rem;

            .footer_bottom-grid {
              grid-column-gap: 1rem;
              grid-row-gap: 1rem;
              grid-template-rows: auto;
              grid-template-columns: .35fr .85fr;
              grid-auto-columns: 1fr;
              display: grid;

              .footer_copyright {
                color: #ffffffa3;
                font-size: .8125rem;
                line-height: 1.57;
              }

              .footer_bottom-right {
                grid-column-gap: 2rem;
                grid-row-gap: 2rem;
                flex-flow: wrap;
                justify-content: space-between;
                align-items: flex-start;
                display: flex;
                font-size: .8125rem;

                .footer_legal-wrap {
                  grid-column-gap: 1.5rem;
                  grid-row-gap: 1.5rem;
                  justify-content: flex-start;
                  align-items: center;
                  display: flex;

                  .footer_legal-link {
                    color: #a7a7a7;
                    line-height: 1.75;
                    transition: color .3s cubic-bezier(.165,.84,.44,1);
                    text-decoration: none;
                  }
                  .footer_legal-link:hover {  
                    color: #90E41E;
                  }
                }

                .footer_social-wrap {
                  grid-column-gap: .75rem;
                  grid-row-gap: .75rem;
                  justify-content: space-between;
                  align-items: flex-end;
                  display: flex;

                  .footer_social-link {
                    color: var(--neutral--white);
                    transition: color .3s cubic-bezier(.165,.84,.44,1);

                    .icon-embed-xsmall {
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      width: 1.5rem;
                      height: 1.5rem;
                      display: flex;
                    }
                  }
                  .footer_social-link:hover {  
                    color: #90E41E;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .footer_component {
    background-color: $color-black;
    color: $color-white;
    padding-top: 5rem;
    padding-bottom: 3.5rem;

    .padding-global {
      padding-left: 5%;
      padding-right: 5%;

      .container-large {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .footer_wrap {
          .footer_top {
            padding-bottom: 2rem;
            .footer_grid {
              grid-template-columns: 1fr;

              .footer_company {
                justify-content: flex-start;

                .footer_logo-icon {
                  width: 8.25rem;
                }
                
                .footer_company-bottom {
                  grid-column-gap: 2rem;
                  grid-row-gap: 2rem;
                  flex-flow: column;
                  flex: 1;
                  display: flex;

                  .footer_rating-wrap {
                    grid-column-gap: .75rem;
                    grid-row-gap: .75rem;
                    flex-flow: column;
                    display: flex;

                    .footer_rating-icon {
                      color: #ffc021;
                      width: 100%;
                      max-width: 7.25rem;
                    }
                  }
                  .footer_logo-list {
                    grid-column-gap: 1rem;
                    grid-row-gap: 1rem;
                    display: flex;
                    .footer_logo-wrap {
                      width: 2.75rem;
                      height: 2.75rem;

                      img {
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                        display: inline-block;
                      }
                    }
                  }
                }
              }
              .footer_links-grid {
                grid-template-columns: 1fr 1fr;
                
                .footer_links-col {
                  flex-flow: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  display: flex;
                  .footer_links-title {
                    padding-bottom: 1rem;
                    font-weight: 500;
                    line-height: 1.75;
                  }
                }
              }
            }
          }
          .footer_bottom {
            padding-top: 2rem;

            .footer_bottom-grid {
              grid-column-gap: 1rem;
              grid-row-gap: 1rem;
              grid-template-columns: 1fr;

              .footer_copyright {
                color: #ffffffa3;
                font-size: .8125rem;
                line-height: 1.57;
              }

              .footer_bottom-right {
                grid-template-columns: 1fr;

                .footer_legal-wrap {
                  grid-column-gap: 1rem;
                grid-row-gap: 1rem;
                grid-template-rows: auto;
                grid-auto-columns: 1fr;
                display: grid;
                grid-template-columns: 1fr;

                  .footer_legal-link {
                    color: #a7a7a7;
                    line-height: 1.75;
                    transition: color .3s cubic-bezier(.165,.84,.44,1);
                    text-decoration: none;
                  }
                  .footer_legal-link:hover {  
                    color: #90E41E;
                  }
                }

                .footer_social-wrap {
                  grid-column-gap: .75rem;
                  grid-row-gap: .75rem;
                  justify-content: space-between;
                  align-items: flex-end;
                  display: flex;

                  .footer_social-link {
                    color: var(--neutral--white);
                    transition: color .3s cubic-bezier(.165,.84,.44,1);

                    .icon-embed-xsmall {
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      width: 1.5rem;
                      height: 1.5rem;
                      display: flex;
                    }
                  }
                  .footer_social-link:hover {  
                    color: #90E41E;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {

}

