.pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @media($xs-up) {
    width: 100%;
    margin: 0 auto;
  }

  // Add more space
  &.pagination--pushy {
    margin: 50px 0;
  }

  // Range
  .pagination__range {

    font-size: 16px;
    font-family: "Matter", sans-serif;
    padding: 20px;

    @media($md-up) {
      text-align: center;
      padding: 0;
    }
  }

  .pagination__sequence {
    display: flex;
    margin: 0 12px;
    padding: 0;
    width: auto;

    li {
  
      list-style: none;
      margin: 0 -1px 0 0;
    }

    a, span {

      color: #CDCCCC;
      cursor: pointer;
      display: flex;
      padding: 10px;
      text-decoration: none;

      &.pagination__sequencelink--active, &:hover, &:focus, &:visited {
        color: #CDCCCC;
      }

      &.pagination__sequencelink--active, &:hover {
        color: black;
      }

      &.pagination__sequencelink--active { // Turn off pointer for current page context
        cursor: auto;
      }
    }

    @media($sm-down) {
      display: none;
    }
  }

  // Control wrapper
  .pagination__controls {
    display: flex;
    margin-left: auto;

    @media($xs-down) {
      width: 100%;
    }
  }
  // Steppers
  .pagination__stepper {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    color: black;
    cursor: pointer;
    font-size: 16px;
    font-family: "Matter", sans-serif;
    height: 48px;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;

    @media($xs-down) {
      width: 50%;
    }

    @media($md-up) {
      font-size: 14px;
      height: 49px;
      width: 119px;
    }

    &:hover, &:focus {
      background-color: $color-linework;
    }

    &:before, &:after {
      content: "";
      height: 1.5em;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      margin: 0 5px;
      vertical-align: middle;
      width: 1.5em;
    }

    // Previous Stepper
    &.pagination__stepper--prev {

      color: #000000;
      background: #F5F5F5;
      padding-right: 1em;
      @media($sm-down) {
        border-left: 0;
      }

      &:after {
        display: none;
      }
      &:before {
        background-image: url('/static/src/img/ui-left-black-arrow.svg');
      }
    }

    // Next Stepper
    &.pagination__stepper--next {
      background: #C6FF7E;
      padding-left: 1em;
      @media($sm-down) {
        border-width: 1px 0;
      }

      &:after {
        background-image: url('/static/src/img/ui-right-black-arrow.svg');
      }
      &:before {
        display: none;
      }
    }

    // Disabled Stepper
    &.pagination__stepper--disabled, &:disabled {
      opacity: .3;
      background-color: white;
      pointer-events: none;
    }
  }

}
