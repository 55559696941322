@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
// Search Result
.seachresult {
  display: flex;
  flex-wrap: wrap;
}

 // Location
 .seachresult__details__location {
  padding-bottom: 5px;
  h3 {
    font-family: 'Matter',sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 33.6px;
    letter-spacing: -2%;

    @media ($md-up) {
      font-size: 20px;
    }
    @media ($lg-up) {
      font-size: 24px;
    }
    em {
      text-transform: lowercase;
    }
  }
  }

// Search Result Details
.seachresult__details {
  width: 100%;
  padding: 22px 20px 20px 0;


  @media($md-up) {
    width: 50%;
  }

  span {
    display: inline;
  }

  // Name
  .seachresult__details__name {
    a {
      color: $color-blue-dark;
      display: flex;
      align-items: center;
      flex-wrap: wrap; /* Allow items to stay in one line */
      gap: 10px;
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
      h2 {
        font-family: 'Matter',sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 44.8px;
        letter-spacing: -2%;
        display: inline-block;
        flex-grow: 1; /* Allows h2 to take full width */
        white-space: nowrap; /* Prevents breaking to next line */
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-weight: bold;
        @media($xs-up) { font-size: 26px; }
        @media($sm-up) { font-size: 30px; }
        @media($md-up) { margin-right:0; }
      }
    }
  } 
  // Vertical Line
  .separator {
    width: 100%;
    border: none;
    border-top: 1px solid #ccc; /* Adjust color */
    margin: 10px 0;
    display: block;
    flex-shrink: 0; /* Prevents it from shrinking */
  }
 
  // Rating
  .seachresult__details__rating {
    width: 326px;
    height: 37px;
    border-radius: 6px;
   
    @media($sm-up) {
      width: 90%;
      height: 37px;
    }
    @media($sm-down) {
      width: 90%;
      height: 37px;
    }
    @media($md-up){
      width: 100%;
      height: 37px;
    }

    .rating__title{

      display: none;
    }

    .rating_component {

      margin-top: 0;
    }
  }

  .seachresult_awards_titleSingular{
    width: 66.666%;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    margin-top: 10px;
    color: $color-black;
    span {
      font-weight: bold;
    }
  }

  // Address
  .seachresult__details__address {
    font-weight: lighter;
    line-height: 1.5;
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    @media($sm-down) {
      display: none;
    }

    // Modifier: --geolindexed (This is clickable, activates on map)
    &.seachresult__details__address--geoindexed {

      height: 44px;
      outline: 0;
      &:hover, &:active {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    // Pin
    .seachresult__details__address__pin {

      img {
        width: 37px;
        height: 46px;
        margin-bottom: 5px;
      }
    }
    // Listing
    .seachresult__details__address__listing {
      width: 85%;
      padding-left: 10px;
      font-family: "Matter", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 5px;
      letter-spacing: 0%;
      color: $color-black;
    }
  }
  @media($md-up) { border-right: 1px solid #d7d7d7;;}
  @media ($lg-up), ($xl-up) {border-right: 1px solid #d7d7d7;;}

  // Single audience
  .seachresult__awards__ratings__audience {

    display: flex;
    flex-direction: column;

    // Count
    .seachresult__awards__ratings__count {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        font-family: "Mulish", sans-serif;
        background-color: #F8F8F8;
        padding: 7px, 9px, 7px, 9px;
        line-height: 15.06px;
        width: 326px;
        height: 37px;
        border-radius: 6px;  
        @media($sm-down) {
          width: 90%;
          height: 37px;
        }
        @media($md-up){
          width: 100%;
          height: 37px;
        }
      p {
        font-family: "Mulish", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 15.06px;
        letter-spacing: 0%;   
        color: #6A6A6A;
      }
      .rating__summary{
        margin-left: 10px;
      }
      .rating__summary_padding{

        margin: 0 5px;
      }
    }
    // Summary
    .seachresult__awards__ratings__summary {
      width: 66.666%;
      margin-top: 6px;
      p {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 6px;
        margin-top: 6px;
        color: $color-black;
        span {
          font-weight: bold;
        }
      }
    }
  }

    // Single audience
    .seachresult__awards__ratings__audience {
      display: flex;
      flex-direction: column;
      // Count
      .seachresult__awards__ratings__count {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          font-family: "Mulish", sans-serif;
          background-color: #F8F8F8;
          padding: 7px, 9px, 7px, 9px;
          line-height: 15.06px;
          width: 326px;
          height: 37px;
          border-radius: 6px; 
        
          @media($sm-down) {
            width: 90%;
            height: 37px;
          }
          @media($md-up){
            width: 100%;
            height: 37px;
          }
        p {
          font-family: "Mulish", sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 15.06px;
          letter-spacing: 0%;   
          color: #6A6A6A;
        }
        .rating__summary{
          margin-left: 10px;
        }
        .rating__summary_padding{

          margin: 0 5px;
        }
      }
      // Summary
      .seachresult__awards__ratings__summary {
        width: 66.666%;
        p {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 6px;
          margin-top: 6px;
          color: $color-black;
          span {
            font-weight: bold;
          }
        }
      }
    }

}

// Search Result Awards
.seachresult__awards {
  position: relative;
  width: 100%;
  padding-left: 0;

  @media($md-up) {
    width: 50%;
    padding: 22px 20px 20px 25px;
  }

  Grey divider on large screens
  &:after {
    height: 75%;
    position: absolute;
    content: "";
    background: $color-linework;
    top: 100px;
    left: -10px;
    @media($md-down) {
      display: none;
    }
  }

  // Header
  .seachresult__awards__header {

    width: 264px;
    position: relative;
    margin-bottom: 22px;
    height: 44px;
    h3 {
      font-weight: bold;
      margin: 0;
      font-size: 20px;
      background: white;
      display: inline-block;
      font-family: 'Matter', sans-serif;
      line-height: 24px;
      font-weight: 510;
      letter-spacing: 0%;
      @media($sm-up), ($sm-down){
        font-size: 14px;
      }
      @media ($sm-up) {
        font-size: 20px;
      }
    }

    p {
      margin: 0;
      padding-top: 5px;
      font-weight: normal;
      font-family: "Matter", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      letter-spacing: 0%;
      color: #8E8E93;
    }

    &:after {
      content: "";
      width: 100%;
      height: 0px;
      top: 33%;
      background: $color-linework;
      position: absolute;
      z-index: -1;
      width: 100%;
      left: 0px;
      display: block;

      @media($md-up) {
        display: none;
      }
    }
  }

  // Ratings
  .seachresult__awards__ratings {
    display: flex;
    flex-direction: column;
    .star {
      width: 19.4px;
      height: 19.4px;
      display: inline-block;
      clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
      background-color: #ccc; /* Default empty star */
    }
    
    .full {
      background-color: #FFA432; /* Full gold star */
    }
    
    .empty {
      background-color: #ccc; /* Empty star */
    }

    // Single audience
    .seachresult__awards__ratings__audience {
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      // Count
      .seachresult__awards__ratings__count {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          font-family: "Mulish", sans-serif;
          background-color: #F8F8F8;
          padding: 7px, 9px, 7px, 9px;
          line-height: 15.06px;
          width: 326px;
          height: 37px;
          border-radius: 6px; 
          padding-left: 9px;
       
          @media($sm-down) {
            width: 90%;
            height: 37px;
          }
          @media($md-up){
            width: 100%;
            height: 37px;
          }
        p {
          font-family: "Mulish", sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 15.06px;
          letter-spacing: 0%;   
          color: #6A6A6A;
        }
        .rating__summary{
          margin-left: 10px;
        }
        .rating__summary_padding{

          margin: 0 5px;
        }
      }
      // Summary
      .seachresult__awards__ratings__summary {
        width: 66.666%;
        p {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 6px;
          margin-top: 6px;
          color: $color-black;
          span {
            font-weight: bold;
          }
        }
      }
    }

  }
  .seachresult__awards__badges {

    display: flex;
    flex-wrap: wrap; 
    gap: 10px; 
    justify-content: flex-start; 
    gap: 24px;
    margin-top: 24px;

    .seachresult__awards__badges__single {
      width: 60px; 
      height: 60px; 
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        width: 60px;
        height: 60px;
        object-fit: contain; 
      }
    }
  }
  
  // Simple list of badges
  .seachresult__awards__badgelist {
    ul {
      padding-left: 20px;
      margin: 20px 0;
      li {
        font-size: 14px;
        margin: 10px 0;
      }
    }
  }


}

// HQ Flag
.seachresult__hqflag{
  position:absolute;
  right:20px;
  top:0;
  z-index:10;

  img{ width:30px; }
}

@media ($md-up), ($lg-up) {
  .seachresult_header {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .seachresult_header_branding_logo{
      display: flex;
      align-items: center;

      img {
        max-width: 110px;
        max-height: 75px;
      }
      .profileintro__branding__logo {
      
        a{
          display: block;
          @media($sm-up){
            &:hover, &:focus { outline: solid 1px #ccc; }
          }
        }
      }
    }

    .seachresult_header_title{

      margin-left: 0px;
    }
  }
  .seachresult_body{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border-top: 1px solid #d7d7d7;
  }
}

@media ($sm-down),($md-down)  {
  
  .profileintro__branding__logo {
    width: 100px;
  }
}

.seachresult__details__name {
  a {
    color: #094C3D;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allow items to stay in one line */
    gap: 10px;
    text-decoration: none;

    h2 {
      font-family: 'Matter',sans-serif;
      font-weight: 600;
      font-size: 32px;
      line-height: 44.8px;
      letter-spacing: -2%;
      display: inline-block;
      flex-grow: 1; /* Allows h2 to take full width */
      white-space: normal; /* allow breaking to next line */
      margin: 0;
      font-weight: bold;
      @media($xs-up) { font-size: 26px; }
      @media($sm-up) { font-size: 30px; }
      @media($lg-up) { font-size: 30px; }
      @media($lg-down) { font-size: 22px;margin-right:0; }
    }
  }
} 
