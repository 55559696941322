// Proximity Card
.proximitycard {
  display: flex;
  align-items: center;

  // No map, because geodata not present
  &.proximitycard--nomap {
    .proximitycard__map {
      display: none;
    }
    .proximitycard__info {
      width: 100%;
    }
  }

  // Info
  .proximitycard__info {
    width: 100%;

    // Name/Link
    .proximitycard__info__name {

      font-family: "Matter", sans-serif;
      font-size: 20px;
      color: #094c3d;
      margin: 10px 0;

    }
    // Rating
    .proximitycard__info__rating {
      margin: 10px 0;
    }

    // Address
    .proximitycard__info__address {
      margin: 10px 0;
      line-height: 1.4;
      span {
        display: block;
      }
    }
  }

}