// Alert Slots are a container for alerts. Due to the graphic design the Desktop/mobile alerts show in two different areas of the DOM

// Hide/show desktop/mobile alertslots based on screen size
.alertslot {
  &.alertslot--desktop {
    @media($sm-down) {
      display: none;
    }
  }
  &.alertslot--mobile {
    @media($sm-up) {
      display: none;
    }
  }
}

// When an alert is showing in compact mode, add some spacing
.header--compact {
  .alertslot--mobile {
    .alert {
      margin-bottom: 10px;
    }
  }
}