.aoesidebar {

  // Second Tier
  &.aoesidebar--nested {
    padding-left: 30px;
    margin-bottom: 10px;
    display: none;
  }

  h2 {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: -2%;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      border-bottom: solid 1px lighten($color-dullgrey, 40%);
      font-size: 14px;
      position: relative;
      &:last-of-type {
        border-bottom: 0;
      }

      a {
        color: $color-text-green-dark;
        text-decoration: none;
        display: block;
        padding: 15px 10px;
        font-family: "Font Name", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: -0.02em;
        &:hover, &:focus {
          text-decoration: underline;
        }

        // Selected
        &.selected {
          font-weight: 700;
          // Show Nested Children
          + .aoesidebar--nested {
            display: block;
          }
        }
      }
    }
  }

}