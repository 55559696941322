// Best Match Info component appears in an overlay on search
.bestmatchinfo__content {
  max-width: 870px;
  line-height: 1.5;
  font-size: 14px;
  padding: 40px 20px 30px 20px;
  @media($sm-up) {
    padding: 50px;
  }

  h2 {
    font-weight: normal;
    color: $color-text-green-dark;
    margin: 10px 0;
    font-size: 24px;
    @media($sm-up) {
      font-size: 32px;
    }
  }

  p {
    margin: 10px 0;
  }
}

//When contained in a stand-alone block
.bestmatch--standalone {
  display: flex;
  line-height: 1.5;
  font-size: 14px;
  padding: 40px 25px 30px 0;
  width: 100%;

  .bestmatchinfo__content {
    margin: 0 auto;
    max-width: 1200px;

    @media($sm-up) {
      padding-right: 15%;
      padding-right: 160px;
    }
  }

}
