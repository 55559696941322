// Button Groups are used for single and multi-button displays on the home/industry templates
.buttongroup {

  padding: 0px 20px;
  width: 100%;

  @media ($lg-up) {
    
    display: flex;
    justify-content: space-between; 
    width: 100%;
    padding: 0;

    button{
      flex: 1; 
      text-align: center;
    }
  }

  // Button, Links
  button, a {
    background: $color-blue-dark;
    color: 000000;
    font-size: 14px;
    padding: 5px 15px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    border: 0;

    @media($sm-up),($lg-up), ($xl-up) {
      width: 359px;
      height: 48px;
    }
    @media($sm-up) {
      width: 325px;
      height: 48px;
      font-size: 20px;
      padding: 15px 55px;
    }

    // First button is flush-left
    &:first-of-type {
      margin-left: 0;
    }

    // Light Variation
    &.light {
      background-color: transparent;
      color: #000000;
      cursor: not-allowed;
    }

    &.active {
      color: $color-text-green-dark;
      border-bottom: 1px solid $color-text-green-dark;
    }

    &:hover, &:focus {
      cursor: pointer;
    }
  }

  // Modifier: centered
  &.buttongroup--centered {
    text-align: center;
  }

  // Modifier: space above
  &.buttongroup--spaceabove {
    margin-top: 30px;
  }

  // Modifier: space below
  &.buttongroup--spacebelow {
    margin-bottom: 30px;
    @media($sm-up) {
      margin-bottom: 70px;
    }
  }

  // Modifier: hidden
  &.buttongroup--hidden {
    opacity: 0;
    pointer-events: none;
    margin-bottom: 0;
  }

  // Modifier: --tablike is used for multi-award interface on industry
  &.buttongroup--tablike {

    display: flex;
    flex-wrap:wrap;
    margin-top: 20px;
    
    @media ($lg-up) {
      border-bottom: 1px solid #00000026;
    }
    
    button, button.active, a, a.active {
      
      font-size: 16px;
      font-family: "Matter", sans-serif;
      white-space: nowrap;
    }
  }

}