.targetedawardlogos {

  width: 224px;
  height: 224px;
  position: relative;

  @media ($md-down) {
    
    width: 125px;
    height: 150px;
  }

  @media ($sm-down) {

    height: 100px;
  }
 
  .targetedawardlogos__single {
    width: 100%;
    height: auto;
    position: absolute;
    display: none;

    @media($sm-down), ($sm-up){
      width: 100px;
    }
    @media ($md-up){
      width: 100%;
    }
    &.targetedawardlogos__single--active {
      display: block;
    }
  }

}