.loadanimation {
  position: fixed;
  left: 0;
  height: 80px;
  pointer-events: none;
  opacity: 0;
  transition: .25s $springIn opacity;
  transition-property: opacity transform;
  top: 0;

  // Modifier: --isloading
  &.loadanimation--isloading {
    opacity: 1;
  }

  // Spinner graphic
  img {
    width: 80px;
    animation: rotate .7s linear infinite;
  }
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}