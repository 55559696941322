// Client ratings component styles

.section_light-brands {
    z-index: 2;
    background-color: #f5f5f0;
    padding-bottom: 4rem;
    position: relative;
    overflow: hidden;

    .padding-global {
        padding-left: 5%;
        padding-right: 5%;
    }

    .container-large {
        margin-right: auto !important;
        margin-left: auto !important;
        width: 100%;
        max-width: 80rem;

        .light-brands_component {
            padding-top: 4rem;

            .light-brands_heading-wrap {
                grid-column-gap: 3.5rem;
                grid-row-gap: 3.5rem;
                justify-content: center;
                align-items: center;
                display: flex;

                .icon-embed-custom {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 8.0625rem;
                    height: .125rem;
                    display: flex;

                    .w-embed:before, .w-embed:after {
                        content: " ";
                        grid-area: 1 / 1 / 2 / 2;
                        display: table;
                    }

                    .w-embed:after {
                        clear: both;
                    }
                }
                .text-size-xlarge {
                    text-align: center;
                    font-size: 1.375rem;
                }
                .font-matter {
                    font-family: Matter, Arial, sans-serif;
                }

            }
        }
    }

    .light-brands_marquee-wrap {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
        position: relative;

        .light-marquee_overlay-left {
            z-index: 1;
            background-image: linear-gradient(270deg, #f5f5f000, #f5f5f0);
            width: 22.9375rem;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            inset: 0% auto 0% 0%;
        }

        .light-brands_marquee-list {
            display: flex;
            position: absolute;

            .light-brands_marquee-item {
                // filter: grayscale();
                flex: none;
                width: 100vw;
                margin-right: 4rem;

                img {
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    display: inline-block;
                }
            }

            .light-brands_marquee-item {
                -webkit-box-flex: 0;
                -ms-flex: none;
                flex: none;
                width: 100vw;
                margin-right: 4rem;
            }
        }

        .light-marquee_overlay-right {
            z-index: 1;
            background-image: linear-gradient(90deg, #f5f5f000, #f5f5f0);
            width: 22.9375rem;
            position: absolute;
            inset: 0% 0% 0% auto;
        }
    }

    .light-brands_awards-wrap {
        grid-column-gap: 3rem;
        grid-row-gap: 3rem;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        display: flex;
        
        .light-brands_awards {
            width: 100%;
            max-width: 6.25rem;

            img {
                transition: transform .6s;

                &:hover {
                    -ms-transform: scale(1.5);
                    -webkit-transform: scale(1.5);
                    transform: scale(1.5); 
                }
            }
        }
    }
}