@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// Profile Testimonials
.profiletestimonials {
  // Filter
  .profiletestimonials__filter {
     display: flex;
      width: fit-content;
      height: auto;
      gap: 8px;
      padding: 25px 0 10px;
      background-color: #FFFFFF;
      position: relative;
      border-bottom: 0.5px solid #aaa;
      select {
        width: 100%;
        font-size: 16px;
        font-family: Matter, sans-serif
      }
  }
  // Collection
  .profiletestimonials__collection {

    // Single
    .profiletestimonials__collection__single {
      margin: 0 auto;
      padding: 20px 20px;
      border: solid 1px #aaaaaa;
      border-radius: 8px;
      @media ($xs-down) { 
        width: 100%; 
        padding: 15px; 
        font-size: 14px; 
        line-height: 20px;
        margin-top: 15px;
      }
    
      @media ($xs-up) {
        width: 100%;
        margin-top: 20px;
        padding: 40px;
      }
    
      @media ($md-up) {
        padding: 15px 15px;
      }
        // Modifier: compact
      &.profiletestimonials__collection__single--compact {
        @media($xs-up) {
          width: 100%;
        }
        @media($md-up) {
          width: 80%;
          padding: 20px 0;
          margin: 0px auto;
        }
        &:first-of-type {
          margin-top: 20px;
        }
      }

      .testimonial-quotes {
        color: #ffa433;
        font-style: italic;
        margin-right: 5px;
        font-size: 36px;
      }

      .testimonial-content {
        font-size: 20px;
      }

      .testimonial-author {
        color: #ffa433
      }
      p {
        margin: 0 0 20px 0;
        font-family: 'Matter', sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0%;
        @media ($sm-down) {
          font-size: 14px;
        }
      }
      cite {
        font-family: 'Matter', sans-serif;
        display: inline-block;
        font-size: 16px;
        display: flex;
        gap: 5px;
        font-style: normal;
      }
    }
  }

  // Pagination
  .profiletestimonials__pagination {

    margin: 0 -20px;
    overflow: hidden;

    @media($sm-up) {
      margin: 0;
      padding-top: 20px;
    }

  }
  .profiletestimonials__arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 8px;
    height: 8px;
    border: solid #333;
    border-width: 2px 2px 0 0;
    transform: rotate(135deg);
    pointer-events: none;
  }

}
