.stars {
  // background: #F8F8F8;
  display: flex;
  flex-wrap: wrap;
  width: 120px;
  @media($xs-up) {
    width: 164px;
  }

  .stars__star {
    margin: 0 2px;
    text-align: center;
    border-radius: 7px;
    padding: 3px;
    width: 19.4px;
    height: 19.4px;
    display: inline-block;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    background-color: #ccc;
    position: relative;


    @media($xs-up) {
      padding: 4px;
      border-radius: 7px;
    }

    // Label
    label {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    // Star Graphic
    &:before {
      content: "";
      display: block;
      background-image: url('/static/src/img/ui-star-empty.svg');
      width: 14px;
      height: 14px;
      position: relative;
      z-index: 1;

      @media($xs-up) {
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
      }
    }
  }
}