.rating_component{

  margin-top: 20px;
// Title
.rating__title {
  font-family: "Matter", sans-serif;;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0%; 
  text-transform: uppercase;
  color: $color-black;
}

// Rating
.rating {
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 9px;

  @media ($md-up) {
    gap: 0;
  }

  @media ($lg-up) {
    gap: 10px;;
  }

  // Star Count
  .rating__stars {
    margin-right: 10px;
    @media($sm-up) {
      width: 97px;
    }
    @media($sm-down) {
      margin: 10px auto;
    }
  }

  // Summary
  .rating__summary {
    
    padding: 10px 0;

    @media($xs-up) {
      text-align: left;
    }

    @media($sm-down) {
      margin: auto;
    }
  
    p {
      margin: 0;
      font-size: 12px;
      font-family: 'Mulish', sans-serif;
      font-weight: 400;
      color: #6A6A6A;

      @media ($lg-down) {
        font-size: 10px;
      }
    }
  }
  
  // Toggle
  .rating__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;           
    padding: 10px 20px;
    background:  #C7F860;   
    border-radius: 6px;     
    @media ($md-up) {
      max-width: 131px;  
    }
    @media($sm-down) {
      margin: auto;
    }

    // Button
    button {
      // Stateful Display Text
      background: none;
      border: none;
      color: #171717;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        &.isclosed {
        }
        &.isopened {
          display: none;
        }
      }
    }
  }

  // Rating Breakdown
  .rating__breakdown {
    display: none;
    padding: 10px 0 10px 5px;
    width: 100%;

    // Each line/star
    .rating__breakdown__line {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      // Label
      .rating__breakdown__line__label {
        padding-right: 10px;
        @media(min-width: 768px) and (max-width: 991px){
          width: 100%;
          font-size: 12px;
        }
      }

      .rating__breakdown__line__label__one__star {
        padding-right: 18px;
        @media(min-width: 768px) and (max-width: 991px){
          width: 100%;
          padding-right: 10px;
          font-size: 12px;
        }
      }

      // Meter
      .rating__breakdown__line__meter {
        width: 100px;

        // Progress Indicator
        .rating__breakdown__line__meter__progress {
          background-color: $color-linework;
          height: 12px;
          text-indent: -9000px;
        }
      }
      // Percent
      .rating__breakdown__line__percent {
        padding-left: 10px;
      }
    }
  }

  /************************** Begin Modifiers **********************/

  // Modifier: opened
  &.rating--opened {
    .rating__breakdown {
      display: block;
    }
    // Update stateful display text
    .rating__toggle {
      button span {
        &.isclosed {
          display: none;
        }
        &.isopened {
          display: inline;
        }
      }
    }
  }

  // Modifier --primary is the rating that appears on Profiles
  &.rating--primary {
    margin-top: 6px;
    background: #F8F8F8;
    max-width: 100%; 
    display: flex;
    flex-wrap: wrap;       
    align-items: center;   
    border-radius: 6px;   
    font-family: Matter, sans-serif; 
    font-size: 12px;     
    line-height: 15.06px;  

    @media ($sm-up) {
      justify-content: space-between; 
    }

    @media ($lg-up) {
      width: 628px;         
      height: auto;         
      padding: 7px 9px;
      // left: 110px;
    }

    @media ($sm-down), ($lg-down){ 
      width: 100%;    
      left: 0;  
      padding: 10px;           
    }
      
    @media($sm-down) {
      .rating__stars, .rating__toggle, .rating__summary {
        margin: 0;
      }
      .rating__toggle {
        padding-left: 10px;
      }
    }

    @media($xs-down) {
      .rating__summary {
        order: 10;
        padding: 10px 0;
      }
      .rating__toggle {
        padding-left: 10px;
      }
    }
  }

  // Modifier --primary-search
  &.rating--primary-search {

    .rating__title {
      h2 {
       display: none;
      }
    }
    .rating__toggle {
      display: none;
    }
    .rating__summary {
      span {
        display: inline;
      }
    }
    @media($sm-down) {
      .rating__stars, .rating__toggle, .rating__summary {
        margin: 0;
      }
    }
    @media($xs-down) {
      .rating__summary {
        order: 10;
        span {
          display: none;
        }
      }
    }
    @media ($lg-up) {
      width: 326px;
      height: 37px;
    }
  }

  // Modifier --secondary (sidebar hq rating)
  &.rating--secondary {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 7px 9px;
    margin-top: 10px;
    border-radius: 6px;
    background: #F8F8F8;
  
    .rating__stars {
      order: initial;
    }
    
    .rating__toggle {
      button {
        font-family: Matter, sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0%;
        text-align: center;
        border-radius: 4px;        
      }
      order: initial;
    }
    
    .rating__summary {
      padding: 0 10px 0 0;
      p{
      font-family: Mulish, sans-serif;
      font-weight: 400;
      font-size: 9px;
      line-height: 11.3px;
      color: #6A6A6A;
      }
    }
  
    /* Media Queries */
    @media ($lg-up) {
      width: 392px;
      height: auto;
      
      .rating__stars {
        order: -1;
      }
      .rating__toggle {
        order: -1;
        margin-left: auto;
        margin-top: 10px;
      }
      .rating__summary {
        width: 100%;
        margin-top: -0.9rem;
      }
    }
    
    @media ($md-up) {
      .rating__summary {
        width: 100%;
      }
    }
    
    @media ($sm-down) {
      .rating__stars,
      .rating__toggle,
      .rating__summary {
        margin: 0;
      }
      .rating__toggle {
        padding: 10px 20px;
      }
    }
    
    @media ($xs-down) {
      .rating__summary {
        order: 10;
        // padding: 10px 0;
      }
      .rating__toggle {
        margin-left: auto;
        padding: 10px 20px;
        margin-top: 10px;
      }
    }
  }
  

}

}