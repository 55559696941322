.promo-banner {
  background: white;
  color: $color-light-grey;
  font-size: 16px;
  text-align: center;

  @media($sm-down) {
    display: none;
  }
}

.promo-banner__button-container {
  padding: 0 20px;
  width: 100%;
  margin: 25px 0;
}

.promo-banner__button {
  background: $color-green-corporate;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px 20px;
  margin: 5px 10px 5px 0;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  border: 0;

  &:hover, &:focus {
    color: white;
    cursor: pointer;
  }
}