// Lead Generation
.leadgeneration {
  .leadgen-form-component {
    text-align: left;
  }

  .leadgen-section-text {
    width: 100%;
    height: auto;
    font-family: 'Matter', sans-serif; 
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0%;
    color: #27272A;
    text-align: left;
    width: 100%;

    @media($lg-down){
      font-size: 15px;
      padding: 10px;
    }
  
    @media ($sm-down) {
      font-size: 14px; 
      line-height: 24px; 
      padding-left: 8px; 
    }
    
  }

  .leadgen-section-title {
    font-family: 'Matter', sans-serif; 
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0%;
    padding: 20px 20px 0 0;
    width: 100%;

    @media($lg-down){
      font-size: 17px;
      padding: 10px;
      line-height: 25px;
    }

    @media ($sm-down) {
        font-size: 16px; 
        padding: 8px; 
    }
  }

  .leadgen__section_1 {
    vertical-align: top;

    @media ($sm-down) {
      width: 100%; 
    }
  }

  .leadgen__section_2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    // border-width: 0.87px;
    border-bottom: 0.87px solid #DCDCDC;
    background: #FFFFFF;
    justify-content: space-between;

    @media($lg-down){
      width: calc(50% - 16px); // Adjust for margins
      height: auto;
      margin: 16px 0;
      min-height: 275px;
    }

    @media ($sm-down) {
      width: 100%; 
      height: auto;
      margin: 10px auto; 
      padding: 18px;
      min-height: 0;
    }

  }
    
}

  .leadgen__section_3 {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    border-bottom: 0.87px solid #DCDCDC;
    background: #FFFFFF;
    justify-content: space-between;
    height: auto;

    @media($lg-down){
      width: calc(50% - 16px); // Adjust for margins
      height: auto;
      margin: 16px 0;
    }

    @media ($sm-down) {
      width: 100%; 
      height: auto;
      margin: 10px auto; 
      padding: 18px;
    }

  }




  .leadgen-button-container {

    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0;
    margin-top: 20px;
    color: $color-text-green-dark;
    font-family: "Matter",sans-serif;

    a {
      font-size: 17px;
      text-decoration: underline;
      display: flex;
      align-items: center;
      gap: 5px;
      color: #094C3D;
      margin-bottom: 10px;
    }

    @media ($sm-down) {
      padding: 7px 0px;
    }
 
    @media (max-width: 1023px) { 
      flex-direction: column;
      align-items: center;
    }
  }

  .leadgen-button {
    width: 100%;
    height: auto;
    font-family: 'Matter', sans-serif; 
    border-radius: 4px;
    padding: 15px;
    text-align: center;
    background-color: #C7F860;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;

    @media ($lg-down) { 
      height: auto;
      padding: 12px;
      font-size: 13px;
    
    } 
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  .leadgen__container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  
    @media ($sm-down) {
        text-align: center; 
        display: flex;
        flex-direction: column;
        align-items: center;
     
    }
    @media ($lg-down) {
      flex-direction: row; 
      justify-content: flex-start;
      align-items: flex-start;
    }
    
  }

