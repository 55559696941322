/**
 * Custom Autocompleter styles
 */


.header--deluxe{
    .searchform__input--location{
        .autocompleter{
            &.autocompleter-show{
                &:after{
                    @media($xs-down){background-color:#0096A3;}
                }
            }
        }
    }
}