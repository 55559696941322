.searchbackdrop {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
  @media($xs-up) {
    display: none;
  }
}