@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// Profile Mini bio
.profileminibio {

  // Title
  .profileminibio__title {
    position: relative;


    h2 {
      font-family: Matter, sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 25.2px;
      letter-spacing: -2%;
      color: #000000;

      @media($md-up) {
        font-size: 18px;
      }
      @media ($lg-down) {
        font-size: 15px;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      top: 1em;
      position: absolute;
      z-index: -1;
      width: calc(100% + 40px);
      left: -20px;

      @media($sm-up) {
        width: 100%;
        left: 0;
      }
    }
  }

  // Subtitle
  .profileminibio__subtitle {

    position: relative;
    padding: 20px 0 5px 0;
    margin-top: 10px;
    border-top: 1px solid #A5A5A5;

    h3 {
      font-family: Matter, sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 25.2px;
      color: #000000;
      margin: 0;
      display: block;
      background: white;
      z-index: 0;
      position: relative;
      @media($sm-up) {
        padding: 0 5px 0 0;
      }
      @media ($lg-down) {
        font-size: 15px;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      top: 50%;
      position: absolute;
      z-index: -1;
      width: 100%;
      left: -20px;
      display: none;

      @media($sm-up) {
        display: block;
        left: 0;
      }
    }
  }

  .profileminibio__subtitle__margin {
    margin-top: 30px;
  }

  .profilemin_bio_no_padding {
    padding: 0 !important;/* Overrides previous padding styles applied to this element */
  }

  .profileminbio__updated {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -2%;
    vertical-align: middle;
    font-size: 12px;
    padding: 10px 0 10px 0 !important; /* Overrides previous padding styles applied to this element */
    color: #696868 !important; /* Ensures this text color is applied even if other styles attempt to override it */

    .underline {
      color: #094C3D;
      text-decoration: none;
    }
  }


  .profileminibio__copy {

    font-family: "Matter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 25.2px;
    letter-spacing: -2%;
    color: #696868;
    padding-bottom: 10px;
    padding-top: 10px;

    &.profileminibio__copy--flatbottom {
      padding-bottom: 25px;

      a {

        font-size: 16px;
        gap: 16px;
        display: inline-flex;
        align-items: center; 
        width: auto; 
        text-decoration: none; 
        color: #094C3D;

        img {
          margin-right: 10px;
        }
      }
    }

    &.profileminibio__copy--flattop {
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: -2%;
      border-bottom: none; 
      text-decoration: none;
    }
   
    a {
      color: #696868;
    }
    @media ($lg-down) {
      font-size: 15px;
      font-weight: 400;
    }
  }

  // Awards
  .profileminibio__awards {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;

    .profileminibio__awards--years-won-wrapper {
      width: 100%;
      margin-top: 20px;
      border: solid 1px #d7d7d7;
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;

      .profileminibio__awards--title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        gap: 10px;
        border-radius: 8px;
        padding: 6px 10px;
        position: absolute;
        background: #EBFEFB;
        top: -20px;
        min-height: 40px;

        @media ($sm-down) {
         width: 50%;
         left: 27%;
        }
        @media ($md-down) {
          width: 65%;
          left: 34%;
         }
         @media ($lg-down) {
          width: 65%;
          left: 34%;
         }

         @media ($xl-down) {
          width: 70%;
          left: 50%;
        }
      
        h4 {
          text-transform: uppercase;
          display: inline-block;
          font-family: Matter, sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;

          @media ($xl-down) {
            font-size: 12px;
            line-height: normal;
          }
        }
        @media ($lg-up) {
          width: 148px;
          height: 40px;
          align-items: center;
        }
        @media screen and (width: 1024px) { 
          // width: 100%;
          height: auto;
          align-items: center;
          h4{
          font-weight: 600;
          line-height: 14px;
          }
        }
        @media ($md-down) {
          height: auto;
          align-items: center;
  
            h4 {
            font-family: Matter, sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
          }
        }

      }

      .profileminibio__awards--margin {

        @media ($lg-up) {
          left: 22%;
        }
      }

      @media ($lg-up) {
        height: auto;        
      }
      @media ($md-down) {
        width: 90%;
      }
      
      
    }

    // Single Award
    .profileminibio__awards__single {
      text-align: center;
      flex: 0 1 47%;
      padding: 30px 5px 20px 5px;
      min-height: 215px;
      min-height: 215px;
      border: solid 1px #d7d7d7;
      border-radius: 10px;
      @media ($xl-down) {
        flex: 0 1 45%;
      }

      // Modifier: --overflow items are not initially visible
      &.profileminibio__awards__single--overflow {
        display: none;
      }

      &.profileminibio__awards__single--with-higher-award {
        flex: auto;
        display: flex;
        justify-content: space-between;
        padding: 30px 0px 20px 0px;

        .profileminibio__awards__single--award-wrapper {
          flex: 0 1 47%;
          border: solid 1px transparent;
        }
      }

      img {
        display: block;
        margin: auto;
        width: calc(100% - 20px);
        border-radius: 100%;
        max-width: 85px;
        max-height: 85px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        font-weight: normal;
        padding: 5px 5px;
        color: #696868;
        margin-top: 10px;
      }
    }
  }

  .awards__none {
    @media($sm-down) {
      display: none;
    }
  }
  // Button
  .profileminibio__button {
    background: #FFF;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    padding: 5px 15px;
    @media($sm-down) {
      display: none;
    }
  }

  .cr-profileminbio-button {

    font-family: "Matter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
    display: flex;
    padding: 7.592px 15.184px;
    justify-content: center;
    align-items: center;
    gap: 5.061px;
    align-self: stretch;
    width: 50%;
    background: #C7F860;
    margin-top: 20px;
  }
  // Link
  .profileminibio__link {

    display: flex; 
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    height: 50px;
    margin: 20px 0;
  
    a {

      display: flex; 
      align-items: center;
      gap: 8px;
      width: 100%;
      font-family: 'Matter', sans-serif;
      font-weight: 400;
      font-size: 17px;
      line-height: 29px;
      letter-spacing: 0%;
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-thickness: 0%;
      color: $color-text-green-dark;

      &:hover, &:focus {
        text-decoration: underline;
      }
      @media (max-width: 1200px){
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      } 
      @media (max-width: 1024px) and (min-width: 768px) { // Medium screens (tablets)
        font-size: 10px;
        font-weight: 300;
        line-height: 28px;
      }
      @media (max-width: 767px) { // Medium screens (tablets)
        font-size: 18px;
        font-weight: 200;
        line-height: 26px;
      }

      img{
        height: 16px;
        width: 16px;
      }
    }

  
    /* Arrow container */
    .profileminibio__arrow {
      flex-shrink: 0;
      width: 43px;
      height: 37px;
      padding: 14.77px;
      background: #C7F860 url('/static/src/img/ph_arrow-square-out.svg') no-repeat center;
      background-size: 12px 12px;
    }
  }

/* This ensures the line is separate from the container */
.profileminibio__separator {
  width: 100%;
  height: 1px;
  background: #DADADA;
  margin-top: 10px; /* Adjust spacing if needed */
}
  
  
  // Rating
  .profileminibio__rating {
    color: #3E3E3E;
  }
  // Address
  .profileminibio__address {

    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    margin-top: 0;
    padding-top: 10px;
    font-family: 'Matter', sans-serif;
    
    span {
      display: block;
      font-size: 16px;
      color: #8e8e8e;
      font-weight: 300;
    }
    a{
      text-decoration: none;
      font-family: 'Matter', sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 25.92px;
      letter-spacing: -2%;
      color: #094c3d;

      &:hover {
        text-decoration: none;
        color: #094c3d;
      }
    }
  }

  // View Map link
  button.profileminibio__viewmap {
    font-size: 14px;
    padding-right: 20px;
    background-image: url('/static/src/img/ui-down-arrow.svg'); 
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center right;
    border: 0;
    background-color: transparent;
    color: #094c3d;
    padding: 0 20px 0 0;
    margin: 10px 0;
    cursor: pointer;
    font-weight: 400;
    font-family: "Matter", sans-serif;
  }
  // Disclaimer
  .profileminibio__disclaimer {

    font-family: 'Matter', sans-serif;
    font-size: 13px;
    line-height: 18.72px;
    letter-spacing: -2%;
    padding: 10px 0;
    margin: 15px 0;
    color: #696868;
  }
}
