.profilepeople {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .profilepeople__card {
    display: flex;
    flex-direction: column; 
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: solid 1px #d7d7d7;
    position: relative;
    align-self: flex-start;
}


  .profilepeople__collection {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
    scroll-behavior: smooth; 
    scrollbar-width: none;
    flex-direction: column;

}



.profilepeople__collection::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome/Safari */
}
  // Pagination
  .profilepeople__pagination {
    margin: 0 -20px;

    @media($sm-up) {
      padding: 20px 0 0 0;
      margin: 0;
    }

  }



  .profilepeople__picture-section {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .profilepeople__verified {
    width: 100px;
    height: 32px;
    display: flex;
    position: absolute;
    top: 3px;
    right: 3px;
    align-self: flex-start; 
  }

  .profilepeople__text-section {
    width: auto; 
    flex: 1; 
    display: flex;
    flex-direction: column;

    .profilepeople__header__container{

      display: flex;
      flex-direction: row;
      gap: 16px;
     
      .profilepeople__image__container{

        display: flex;
        justify-content: center;

        .profilepeople__image {
          max-width: 76px;
          height: 76px;
          border-radius: 100px;
          width: 100%;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      
          &--link {
            width: 100%;
          }
        }
      }

      .profilepeople__title_session {

        .profilepeople__name {
          font-family: 'Matter',sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -2%;
          align-items: flex-start;
          &--link {
            color: #000000;
            text-decoration: none;
          }
        }
  
        .profilepeople__title-container {
          margin-top: 0px;
        }
  
        .profilepeople__title {
  
          font-size: 16px;
          font-weight: 400;
          color: #3D3D3D;
        }
      
        .profilepeople__pronouns {
  
          font-size: 14px;
          font-weight: 400;
          color: #707070;
        }
  
        .profilepeople-stars {
  
          margin: 12px 0 0 0;
          display: flex;
          flex-flow: row wrap;
          gap: 5px;
      
          &__ratings {
            display: flex;
            flex-flow: column;
            justify-content: center;
          }
      
          &__text {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
      
            &--bold {
              font-weight: 600;
            }

            &--light {
              color: #989898;
            }
          }
      
          &__stars {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
          }
        }
      
        :nth-last-child(1 of .profilepeople-stars) {
          padding-bottom: 12px;
        }
  
        .stars__star {
  
          width: 16px;
          height: 16px;
          margin: 0 4px 0 0;
          text-align: center;
          padding: 4px;
          border-radius: 7px;
          position: relative;
          overflow: hidden;
          clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          background: #cccccc;
        }
      }
    }

    .profilepeople__body__container {

      .stars__star label {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    
      .stars__star:before {
        content: "";
        display: block;
        background-image: url('/static/src/img/ui-star-empty.svg');
        width: 14px;
        height: 14px;
        background-size: 14px 14px;
        position: relative;
        z-index: 1;
      }
    
      .profilepeople__bio {

        font-family: "Matter", sans-serif;
        display: block;
        max-width: 100%;
        white-space: normal;
        position: relative;
        font-size: 12px;
        line-height: 20px;
        min-height: 60px; 
        color: #777777;
        text-align: justify; 
      }
    
      .show-more-btn {
        
        display: inline-block;
        font-weight: bold;
        color: #3D3D3D;
        cursor: pointer;
        padding-left: 5px;
        background: white;
      }
    
      .profilepeople__details-link {

        display: flex;
        align-self: flex-end;
        margin-top: 16px;
      }
    
      .profilepeople__link {

        color: #F08A21;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        display: flex;
    
        &:hover {
          text-decoration: underline;
        }
    
        &--text {
          margin: auto;
        }
      }
    
      .union-icon {

        width: 17px;
        height: 17px;
        margin: auto 8px auto 0;
      }

      .profilepeople__bio-container {

        position: relative;
        max-width: 100%;
        display: inline-block;
        
      }
    
      .profilepeople__bio.expanded {
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        overflow: visible;
        white-space: normal;
    }
    }
  }
}

.learn-more-card-title {
  padding: 0 0 15px 0 !important; /* Overrides previous padding styles applied to this element */
}