@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// Profile Intro
.profileintro {
  display: flex;
  flex-wrap: wrap;
  // No logo variation
  &.profileintro--nologo {
    .profileintro__branding {
      .profileintro__branding__logo {
        display: none;
      }
      .profileintro__branding__name {
        width: 100%;
        padding: 0;
      }
    }
  }

  // Reveal Hidden Subspecialties
  &.profileintro--showsubspecialties {
    // Swap Labels
    .profileintro__specialties{
      button{
        color:$color-text-green-dark;
         &:hover, &:focus{color:$color-text-green-dark;}
        .closedtext{display:none;
        }
        .openedtext{display:inline;
          text-decoration: underline;}
      }
    }
    // Show Drawer
    .profileintro__subspecialties{
      display:flex;
      flex-wrap:wrap;
      margin: 0 auto;
      width: 100%;

    }
  }

  // HQ Badge
  .profileintro__hqbadge{
    position:absolute;
    top:0;
    right:25px;
    width:30px;
    img{width:100%;}
  }


  // Branding
  .profileintro__branding {
    display: flex;
    align-items: center;
    width: 100%;

    // Modifier: is hq needs right-padding
    &.profileintro__branding--ishq{
      padding-right: 40px;
    }
    
    // Logo
    .profileintro__branding__logo {
      
      a{
        display: block;
        @media($sm-up){
          &:hover, &:focus { outline: solid 1px #ccc; }
        }
      }

      img {
        max-width: 150px;
        max-height: 60px;
        vertical-align: bottom;
      }
    }

    // Name
    .profileintro__branding__name {
      width: 600px;
      height: auto;

      @media($sm-up){

        padding-left: 25px; 
      }

      h1 {
        font-family: 'Matter', sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 44.8px;
        letter-spacing: -2%;
        color: $color-text-green-dark;
        margin: 0 0 10px 0px;
       
        @media($sm-down){
          margin: 0 0 10px 20px;
        }

        @media($md-up){
          margin-bottom: 10px;
        }

        span {
          font-family: 'Matter', sans-serif;
          font-weight: 500;
          font-size: 24px;
          line-height: 33.6px;
          letter-spacing: -2%;
          display: block;          
          color: $color-black;

          @media($sm-down) { 
            font-size: 18px; 
          }

          em { text-transform: none; }
        }
      }
    }

  }

  // Testimonials
  .profileintro__ratings {
    width: 100%;
    @media ($lg-up) {
      margin: 0 auto;
      width: 100%;
      height: auto;
      margin-left: 100px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    

    @media ($md-up) {
      margin: 0 auto;
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .rating_component {
      width: calc(50% - 10px);
      @media (max-width: 991px) {
        width: 100%;
      }

      .rating {
        display: flex;
        justify-content: space-between;
      }

      .rating__toggle {
        @media ($lg-down) {
          max-width: 100px;
        }

        @media (max-width: 1244px) {
          width: 100px;
        }
      }

      .rating__summary {
        width: 180px;
        @media ($lg-down) {
          margin: 0 10px;
          width: 130px;
        }

        @media (max-width: 1244px) {
          margin: 0 20px;
          width: 130px;
        }

        p {
          @media (max-width: 1244px) {
            font-size: 10px;
          }
        }
      }
    }
  }

.profileintro__description {
  border-top: 1px solid #D9D9D9;
  margin-top: 20px;
}

.profileintro__specialties {
  .border-box-bottom {
    border-bottom: 1px solid #D9D9D9;
  }

  .profileintro_area_expertise {
    font-family: "Matter",sans-serif;
    font-weight: 600;
    font-size: 22px;
    vertical-align: middle;
    color: #27272A;
    padding: 0; 
    margin-top: 10px    
  }
  .are-exp-subtext {
    font-family: "Matter",sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0%;
    color: #8E8E8E;
  }

  .action--togglesubspecialties {
    font-family: "Matter",sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0%;
    color: #27272A;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-offset: 0%;
    text-decoration-thickness: 0%;

  }
}

// Common Styles for Both Sections
.profileintro__description,
.profileintro__specialties {
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  text-align: center;

  @media (max-width: 991px) {
    margin: 0 auto;
  }

  p {
    font-family: 'Matter', sans-serif;
    font-size: 16px;
    line-height: 27.2px;
    letter-spacing: -2%;
    color: #8E8E8E;
    position: relative;
    text-align: left;
    width: 100%; // Ensures consistent width
    padding: 10px 10px 10px 0;

    @media ($lg-down) {
      font-size: 15px;
      line-height: 24px;    
    }

    @media ($md-down) {
      font-size: 14px;
      line-height: 22px;
      width: 100%;
    }

    @media ($sm-down) {
      font-size: 13px;
      line-height: 20px;
      width: 100%;
    }
  }
}

// Button styles inside Specialties
.profileintro__specialties {
  button {
    appearance: none;
    border: none;
    color: #8E8E8E;
    font-size: inherit;
    background: none;
    text-decoration: none;
    font-family: 'Matter', sans-serif;
    font-size:inherit;
    letter-spacing: -2%;
    padding: 0;
    font-weight: lighter;
    line-height: 27.2px;

    .closedtext {
      text-decoration: underline;
      color:$color-text-green-dark;
      &:hover{
        color: $color-text-green-dark;
      }
 

    }
    .openedtext { display: none; }
  }

  br {
    @media ($md-up) { display: none; }
  }

  .detailsButton{
    display: block;
  }
}


  // Subcategories of Speciality
  .profileintro__subspecialties{
    display:none;
    width: 100%;

    // An AOE subgroup
    .profileintro__subspecialties__group{
      margin-bottom: 20px;
      width: 100%;
      @media($xs-up){width: 25%;  padding: 0 10px 0 0; }
      @media($sm-down){width: 50%;}
      
  
      h2{
        color: #171717;
        font-family: "Matter", sans-serif;
        font-weight: 500;
        font-size: 17px;
        line-height: 29px;
        letter-spacing: 0%;

      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
      }
     li{
        padding: 15px 0;
        color: #171717;
        font-family: "Matter", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -2%;
        color: #8e8e8e;
        position: relative;
        text-align: left;

        border-bottom: solid 1px lighten($color-dullgrey, 40%);
        &:last-of-type{border-bottom:0;}
      }

      button{
        border:solid 1px $color-linework;
        background:white;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        &:hover, &:focus{background-color:$color-linework;}
      }

      // When List is --abbreviated, hide overstock
      &.profileintro__subspecialties__group--abbreviated{
        li.overstock{display:none;}
      }
    }
  }

}

.profileintro--nologo {
  .profileintro__norating  {
    margin-left: 0px;
  }
  .profileintro__ratings  {
    margin-left: 0px;
  }
  .profileintro__description  {
    margin-left: 0px;
  }
  .profileintro__specialties  {
    margin-left: 0px;
  }
  .profileintro__subspecialties  {
    margin-left: 0px;
    max-width: 100% !important;
  }
}


