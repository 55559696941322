// Sponsors
.sponsors {
  border: solid 1px $color-linework;
  border-radius: 10px;
  position: relative;
  align-items: center;
  padding: 25px;
  margin: 25px;
  text-align: center;

  .sponsors__group {
    padding-bottom: 25px;

    img {
      width: 100%;
    }
  }

  // Title
  .sponsors__title {
    position: absolute;
    top: -.7em;
    left: 0;

    h2 {
      font-size: 14px;
      text-transform: uppercase;
      background-color: white;
      display: inline-block;
      margin: 0 0 0 10px;
      padding: 0 10px;
    }
  }
}