.noresults {
  padding: 1vw;
  text-align: center;

  h1 {
    font-weight: normal;
    font-size: 22px;
    @media($sm-up) {
      font-size: 30px;
    }
  }
}