// Homepage Industries Component
.homeindustries {
  width: 100%;

  // Show all modifier
  &.homeindustries--showall {
    .homeindustries__promos, .homeindustries__showmore {
      display: none;
    }
  }
  // Default State
  &:not(.homeindustries--showall) {
    .homeindustries__complete {
      display: none;
    }
  }
}

// Homepage Industries Header
.homeindustries__header {

}

// Featured Industry Promos
.homeindustries__promos {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;

  .homeindustries__promos__single {
    background: grey;
    color: white;
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    padding: 20vw 0;
    margin-bottom: 20px;

    @media($sm-up) {
      width: 19%;
      padding: 7vw 0;
      margin-bottom: 0;
    }

    @media($lg-up) {
      padding: 80px 0;
    }

    &.homeindustries__promos__single--accountants {
      background-image: url('/static/src/img/graphic-industry-accounting.jpg');
    }
    &.homeindustries__promos__single--hrservices {
      background-image: url('/static/src/img/graphic-industry-hr.jpg');
    }
    &.homeindustries__promos__single--lawyers {
      background-image: url('/static/src/img/graphic-industry-lawyers.jpg');
    }
     &.homeindustries__promos__single--staffing {
      background-image: url('/static/src/img/graphic-industry-staffing.jpg');
    }
    &.homeindustries__promos__single--insurance {
      background-image: url('/static/src/img/graphic-industry-insurance.jpg');
    }

    a {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(128, 128, 128, .8);
      height: 100%;
      width: 100%;
      color: white;
      text-decoration: none;

      &:hover, &:focus {
        background: rgba(0, 185, 201, .8);
      }
    }
  }
}

.padding-global {
  padding-left: 5%;
  padding-right: 5%;
}

// Show More Button
.homeindustries__showmore {
  padding: 40px 0;
}

// Complete List of Industries
.homeindustries__complete {

}
.content-home {
  max-width: 1440px;
}
.text-size-xxlarge {
  font-size: 2rem;
}
.font-matter {
  font-family: "Matter",sans-serif;
}
// customer rating
.section_demo-rating{
  width: 100%;
  margin-top: 80px;
  .container-large {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .padding-section-large {
      padding-top: 5rem;
      padding-bottom: 5rem;

      .demo-rating_component {
        .demo-rating_heading {
          text-align: center;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 3rem;
          display: flex;

          .demo-rating_title {
            font-weight: 400;
          }
        }
        .demo-rating_rating-wrap {
          grid-column-gap: 5rem;
          grid-row-gap: 5rem;
          justify-content: center;
          align-items: center;
          display: flex;

          .demo-rating_rating {
            width: 7vw;
            img {
              width: 100%;
              max-width: 100%;
              height: auto;
              display: inline-block;
              transition: transform .6s;

              &:hover {
                -ms-transform: scale(1.5);
                -webkit-transform: scale(1.5);
                transform: scale(1.5); 
              }
            }
          }
        }
      }
    }
  }

}

.section_awards-industry {
  .padding-section-large {
    padding-top: 7rem;
  }

  .padding-global {
      padding-left: 7%;
      padding-right: 7%;
  }
  .awards-industry {
    margin-right: auto;
    display: flex;

    .solution-templates_left {
      width: 18%;
      padding-top: 1.5rem;

      .solution_link.is-dot {
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        justify-content: flex-start;
        align-items: center;
        display: flex;
      }
      
      .solution_link {
          transition: color .25s;
          text-decoration: none;
          .awards-industry_div {
            background-color: #C7F860;
            border-radius: 8.25rem;
            flex: none;
            width: .75rem;
            height: .75rem;
          }
          .text-size-small {
            font-size: .875rem;
            line-height: 1.57;
            color: #000000;
          }
      }
      .w-inline-block {
          max-width: 100%;
          display: inline-block;
      }
    }
    .awards-industry_right {
      grid-column-gap: 5rem;
      grid-row-gap: 1rem;
      flex-flow: column;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      width: 82%;
      display: flex;

      .insight-template_heading-wrap {
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
        flex-flow: column;
        width: 100%;
        display: flex;

        .font-matter {
          font-family: Matter, Arial, sans-serif;
        }
        
        .heading-style-h4 {
            letter-spacing: -.02em;
            font-family: Concrette M TRIAL;
            font-weight: 350;
            font-size: 3rem;
            line-height: 3.875rem;
            margin: 0;
            width: 75%;
        }
        .text-size-medium {
          font-size: 1.125rem;
          line-height: 1.77;
          font-weight: 400;
        }
      }
      .awards-industry_block {
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        flex-flow: column;
        display: flex;

        .awards-industry_row {
          grid-column-gap: 1.75rem;
          grid-row-gap: 1.75rem;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr 1fr;
          grid-auto-columns: 1fr;
          display: grid;
          

          .awards-industry_link {
            border: 1px solid #dadada;
            justify-content: space-between;
            align-items: center;
            padding: .5rem .5rem .5rem 1.25rem;
            font-size: 1.1rem;
            display: flex;
            text-decoration: none;
            color: #000000;

            .awards-industry_link-arrow {
              background-color: #c7f860;
              justify-content: center;
              align-items: center;
              min-width: 2.75rem;
              min-height: 2.75rem;
              display: flex;
              .icon-embed-xxsmall {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 1rem;
                height: 1rem;
                display: flex;
              }
            }
          }
          #w-node-bbfac467-01f8-87c3-649e-a24c93dbd90d-6f2d9b04 {
            grid-area: span 1 / span 2 / span 1 / span 2;
          }
        }
      }
    }
  }
}

.cr-button-updated {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #C7F860;
}

// customer experience section
.section_cta {
  position: relative;
  overflow: hidden;

  .cta_padding {
    padding-left: 5%;
    padding-right: 5%;
  }

  .cta_bg-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0%;

    .img-cover {
      object-fit: cover;
    }
  }

  .padding-section-medium {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .cta_parent {
    z-index: 1;
    background-color: #ffffff;
    border-radius: 1rem;
    width: 100%;
    max-width: 65.875rem;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem 2rem;
    position: relative;

    .cta_wrap {
      text-align: center;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 44rem;
      margin-left: auto;
      margin-right: auto;
      display: flex;

      .cta_heading {
        font-family: Matter, Arial, sans-serif;
        font-weight: 500;
        font-size: 3.25rem;
      }

      .heading-style-h4 {
        letter-spacing: -.02em;
        line-height: 3.75rem;
      }

      .padding-custom1 {
        padding: 0 0 .75rem;
      }

      .padding-bottom {
        padding-top: 0rem !important;
        padding-right: 0rem !important;
        padding-left: 0rem !important;
      }

      .text-color-neutral-600 {
        color: #666666
      }

      .text-size-medium {
        font-size: 1.125rem;
        line-height: 1.77;
      }

      .max-width-small {
        width: 100%;
        max-width: 30rem;
      }

      p {
        margin-bottom: 0;
        margin-top: 0;
      }

      h2 {
        letter-spacing: -.01em;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Concrette M, Arial, sans-serif;
        font-size: 4.125rem;
        font-weight: 400;
        line-height: 1.24;
      }

      .padding-small {
        padding: 1.5rem;
      }

      .button_component {
        .button {
          border: 1px solid #c7f860;
          background-color: #c7f860;
          color: #000000;
          text-align: center;
          border-radius: .25rem;
          padding: .75rem 1.625rem;
          font-weight: 500;
          line-height: 1.75;
          text-decoration: none;
          transition: border-color .3scubic-bezier(.215,.61,.355,1), color .3scubic-bezier(.215,.61,.355,1), background-color .3scubic-bezier(.215,.61,.355,1);
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .content-home {
    max-width: 1440px;
  }
  .text-size-xxlarge {
    font-size: 2rem;
  }
  .font-matter {
    font-family: Matter, Arial, sans-serif;
  }
  .section_demo-rating{
    width: 100%;
    .container-large {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  
      .padding-section-large {
        padding-top: 5rem;
        padding-bottom: 5rem;
  
        .demo-rating_component {
          .demo-rating_heading {
            text-align: center;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 3rem;
            display: flex;
  
            .demo-rating_title {
              font-weight: 400;
            }
          }
          .demo-rating_rating-wrap {
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;
            flex-flow: wrap;
            justify-content: center;
  
            .demo-rating_rating {
              width: 11vw;
              img {
                width: 100%;
                max-width: 100%;
                height: auto;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  .section_awards-industry {
    .padding-section-large {
      padding-top: 6rem;
    }
  
    .padding-global {
        padding-left: 5%;
        padding-right: 5%;
    }
    .awards-industry {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        flex-flow: column;
  
      .solution-templates_left {
        width: 18%;
        padding-top: .25rem;
  
        .solution_link.is-dot {
          grid-column-gap: .5rem;
          grid-row-gap: .5rem;
          justify-content: flex-start;
          align-items: center;
          display: flex;
        }
        
        .solution_link {
            transition: color .25s;
            text-decoration: none;
            .awards-industry_div {
              background-color: #C7F860;
              border-radius: 8.25rem;
              flex: none;
              width: .75rem;
              height: .75rem;
            }
            .text-size-small {
              font-size: .875rem;
              line-height: 1.57;
            }
        }
        .w-inline-block {
            max-width: 100%;
            display: inline-block;
        }
      }
      .awards-industry_right {
        grid-template-columns: 1fr;
        width: auto;
  
        .insight-template_heading-wrap {
          grid-column-gap: 1.5rem;
          grid-row-gap: 1.5rem;
          flex-flow: column;
          width: 100%;
          display: flex;
  
          .font-matter {
            font-family: Matter, Arial, sans-serif;
          }
          
          .heading-style-h4 {
            font-size: 1.75rem;
            width: 100%;
          }
          .text-size-medium {
            font-size: 1.125rem;
            line-height: 1.77;
            font-weight: 400;
          }
        }
        .awards-industry_block {
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column;
          flex-flow: column;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
  
          .awards-industry_row {
            grid-template-columns: 1fr 1fr;

            .awards-industry_link {
              border: 1px solid #dadada;
              justify-content: space-between;
              align-items: center;
              padding: .5rem .5rem .5rem 1.25rem;
              font-size: 1.35rem;
              display: flex;
              text-decoration: none;
              color: #000000;
  
              .awards-industry_link-arrow {
                background-color: #c7f860;
                justify-content: center;
                align-items: center;
                width: 2.75rem;
                height: 2.75rem;
                display: flex;
                .icon-embed-xxsmall {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 1rem;
                  height: 1rem;
                  display: flex;
                }
              }
            }
            #w-node-bbfac467-01f8-87c3-649e-a24c93dbd90d-6f2d9b04 {
              grid-area: span 1 / span 2 / span 1 / span 2;
            }
          }
        }
      }
    }
  }

  .section_cta {
    position: relative;
    overflow: hidden;
  
    .cta_padding {
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .cta_bg-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0%;
  
      .img-cover {
        object-fit: cover;
        height: 100%;
      }
    }
  
    .padding-section-medium {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  
    .cta_parent {
      z-index: 1;
      background-color: #ffffff;
      border-radius: 1rem;
      width: 100%;
      max-width: 65.875rem;
      margin-left: auto;
      margin-right: auto;
      padding: 3rem 2rem;
      position: relative;
  
      .cta_wrap {
        text-align: center;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 44rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
  
        .cta_heading {
          font-family: Matter, Arial, sans-serif;
          font-weight: 500;
          font-size: 1.75rem;
        }
  
        .heading-style-h4 {
          letter-spacing: -.02em;
          line-height: 2.75rem;
        }
  
        .padding-custom1 {
          padding: 0 0 .75rem;
        }
  
        .padding-bottom {
          padding-top: 0rem !important;
          padding-right: 0rem !important;
          padding-left: 0rem !important;
        }
  
        .text-color-neutral-600 {
          color: #666666
        }
  
        .text-size-medium {
          font-size: 1.125rem;
          line-height: 1.77;
        }
  
        .max-width-small {
          width: 100%;
          max-width: 30rem;
        }
  
        p {
          margin-bottom: 0;
          margin-top: 0;
        }
  
        h2 {
          letter-spacing: -.01em;
          margin-top: 0;
          margin-bottom: 0;
          font-family: Concrette M, Arial, sans-serif;
          font-size: 4.125rem;
          font-weight: 400;
          line-height: 1.24;
        }
  
        .padding-small {
          padding: 1.5rem;
        }
  
        .button_component {
          .button {
            border: 1px solid #c7f860;
            background-color: #c7f860;
            color: #000000;
            text-align: center;
            border-radius: .25rem;
            padding: .75rem 1.625rem;
            font-weight: 500;
            line-height: 1.75;
            text-decoration: none;
            transition: border-color .3scubic-bezier(.215,.61,.355,1), color .3scubic-bezier(.215,.61,.355,1), background-color .3scubic-bezier(.215,.61,.355,1);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .hide-mobile-landscape {
  }

  .section_demo-rating{
    width: 100%;
    .container-large {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  
      .padding-section-large {
        padding-top: 5rem;
        padding-bottom: 5rem;
  
        .demo-rating_component {
          .demo-rating_heading {
            text-align: center;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 2rem;
            display: flex;
  
            .demo-rating_title {
              font-weight: 400;
            }
          }
          .demo-rating_rating-wrap {
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;
            flex-flow: wrap;
            justify-content: center;
  
            .demo-rating_rating {
              width: 16vw;
              img {
                width: 100%;
                max-width: 100%;
                height: auto;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  .section_awards-industry {
    .padding-section-large {
      padding-top: 4rem;
    }
  
    .padding-global {
        padding-left: 5%;
        padding-right: 5%;
    }
    .awards-industry {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        flex-flow: column;
  
      .solution-templates_left {
        width: 18%;
        padding-top: .25rem;
  
        .solution_link.is-dot {
          grid-column-gap: .5rem;
          grid-row-gap: .5rem;
          justify-content: flex-start;
          align-items: center;
          display: flex;
        }
        
        .solution_link {
            transition: color .25s;
            text-decoration: none;
            .awards-industry_div {
              background-color: #C7F860;
              border-radius: 8.25rem;
              flex: none;
              width: .75rem;
              height: .75rem;
            }
            .text-size-small {
              font-size: .875rem;
              line-height: 1.57;
            }
        }
        .w-inline-block {
            max-width: 100%;
            display: inline-block;
        }
      }
      .awards-industry_right {
        grid-template-columns: 1fr;
        width: auto;
  
        .insight-template_heading-wrap {
          grid-column-gap: 1.5rem;
          grid-row-gap: 1.5rem;
          flex-flow: column;
          width: 100%;
          display: flex;
  
          .font-matter {
            font-family: Matter, Arial, sans-serif;
          }
          
          .heading-style-h4 {
            font-size: 1.5rem;
            line-height: 1.4;
          }
          .text-size-medium {
            font-size: 1.125rem;
            line-height: 1.77;
            font-weight: 400;
          }
        }
        .awards-industry_block {
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column;
          flex-flow: column;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex
;
  
          .awards-industry_row {
            grid-template-columns: 1fr 1fr;
            
            .list-last-item-1 {
              display: none !important;
            }

            .awards-industry_link {
              font-size: 1.1rem;
  
              .awards-industry_link-arrow {
                width: 2rem;
                height: 2rem;
                .icon-embed-xxsmall {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 1rem;
                  height: 1rem;
                  display: flex;
                }
              }
            }
            #w-node-bbfac467-01f8-87c3-649e-a24c93dbd90d-6f2d9b04 {
              grid-area: span 1 / span 2 / span 1 / span 2;
            }
          }
        }
      }
    }
  }

  .section_cta {
    position: relative;
    overflow: hidden;
  
    .cta_padding {
      padding-left: 0%;
      padding-right: 0%;
    }
  
    .cta_bg-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0%;
  
      .img-cover {
        object-fit: cover;
        height: 100%;
      }
    }
  
    .padding-section-medium {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  
    .cta_parent {
      border-radius: 0;
      padding: 6%;
  
      .cta_wrap {
        text-align: left;
        align-items: stretch;
  
        .cta_heading {
          font-family: Matter, Arial, sans-serif;
          font-weight: 500;
          font-size: 1.75rem;
        }
  
        .heading-style-h4 {
          letter-spacing: -.02em;
          line-height: 2.75rem;
        }
  
        .padding-custom1 {
          padding: 0 0 .75rem;
        }
  
        .padding-bottom {
          padding-top: 0rem !important;
          padding-right: 0rem !important;
          padding-left: 0rem !important;
        }
  
        .text-color-neutral-600 {
          color: #666666
        }
  
        .text-size-medium {
          font-size: 1.125rem;
          line-height: 1.77;
        }
  
        .max-width-small {
          width: 100%;
          max-width: 30rem;
        }
  
        p {
          margin-bottom: 0;
          margin-top: 0;
        }
  
        h2 {
          letter-spacing: -.01em;
          margin-top: 0;
          margin-bottom: 0;
          font-family: Concrette M, Arial, sans-serif;
          font-size: 4.125rem;
          font-weight: 400;
          line-height: 1.24;
        }
  
        .padding-small {
          padding: 1.5rem;
        }
  
        .button_component {
          .button {
            border: 1px solid #c7f860;
            background-color: #c7f860;
            color: #000000;
            text-align: center;
            border-radius: .25rem;
            padding: .75rem 1.625rem;
            font-weight: 500;
            line-height: 1.75;
            text-decoration: none;
            transition: border-color .3scubic-bezier(.215,.61,.355,1), color .3scubic-bezier(.215,.61,.355,1), background-color .3scubic-bezier(.215,.61,.355,1);
          }
        }
      }
    }
  }

}

@media screen and (max-width: 479px) {
  .hide-mobile-landscape {
  }

  .section_demo-rating{
    width: 100%;
    .container-large {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  
      .padding-section-large {
        padding-top: 5rem;
        padding-bottom: 5rem;
  
        .demo-rating_component {
          .demo-rating_heading {
            text-align: center;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 1rem;
            display: flex;
  
            .demo-rating_title {
              font-weight: 400;
            }
          }
          .demo-rating_rating-wrap {
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;
            flex-flow: wrap;
            justify-content: center;
  
            .demo-rating_rating {
              width: 20vw;
              img {
                width: 100%;
                max-width: 100%;
                height: auto;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  .section_awards-industry {
    .padding-section-large {
      padding-top: 4rem;
    }
  
    .padding-global {
        padding-left: 5%;
        padding-right: 5%;
    }
    .awards-industry {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        flex-flow: column;
  
      .solution-templates_left {
        width: 18%;
        padding-top: .25rem;
  
        .solution_link.is-dot {
          grid-column-gap: .5rem;
          grid-row-gap: .5rem;
          justify-content: flex-start;
          align-items: center;
          display: flex;
        }
        
        .solution_link {
            transition: color .25s;
            text-decoration: none;
            .awards-industry_div {
              background-color: #C7F860;
              border-radius: 8.25rem;
              flex: none;
              width: .75rem;
              height: .75rem;
            }
            .text-size-small {
              font-size: .875rem;
              line-height: 1.57;
            }
        }
        .w-inline-block {
            max-width: 100%;
            display: inline-block;
        }
      }
      .awards-industry_right {
        grid-template-columns: 1fr;
        width: auto;
  
        .insight-template_heading-wrap {
          grid-column-gap: 1.5rem;
          grid-row-gap: 1.5rem;
          flex-flow: column;
          width: 100%;
          display: flex;
  
          .font-matter {
            font-family: Matter, Arial, sans-serif;
          }
          
          .heading-style-h4 {
            font-size: 1.5rem;
            line-height: 1.4;
          }
          .text-size-medium {
            font-size: 1.125rem;
            line-height: 1.77;
            font-weight: 400;
          }
        }
        .awards-industry_block {
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column;
          flex-flow: column;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex
;
  
          .awards-industry_row {
            grid-template-columns: 1fr;
  
            .awards-industry_link {
              font-size: 1.5rem;
  
              .awards-industry_link-arrow {
                width: 2rem;
                height: 2rem;
                .icon-embed-xxsmall {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 1rem;
                  height: 1rem;
                  display: flex;
                }
              }
            }
            #w-node-bbfac467-01f8-87c3-649e-a24c93dbd90d-6f2d9b04 {
              grid-area: span 1 / span 1 / span 1 / span 2;
            }
          }
        }
      }
    }
  }

}

