// Award History component appears in an overlay and visualizes past awards
.awardhistory {
  background: white;
  max-width: 800px;
  width: 100%;
  padding: 40px 15px 10px 15px;
  @media($sm-up) {
    padding: 20px;
  }
  color: #000000;

  // Overlay headline
  h1 {
    font-weight: normal;
    margin: 0;
    font-size: 20px;
    @media($sm-up) {
      font-size: 20px;
    }
  }

  // Full set of awards
  .awardhistory__set {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    // Award Header (a year)
    .awardhistory__header {
      width: 100%;
      padding: 20px 0 0 0;
      border-top: solid 1px $color-linework;
      margin-top: 20px;
    }

    // Single Award Entry
    .awardhistory__set__single {
      width: 175px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 6px;
      min-width: 175px;
      min-height: 215px;
      @media($xs-up) {
        padding: 15px 10px;
      }
      @media($sm-up) {
        width: 175px;
        padding: 0 25px;
      }

      // Award Image
      img {
        width: 80%;
      }

      // Award Description
      p {
        margin: 5px 0 0 0;
        font-size: 12px;
        font-size: 3vw;
        @media($xs-up) {
          font-size: 16px;
        }
        @media($sm-up) {
          font-size: 14px;
        }

        &.awardhistory__textonly {
          color: #000;
        }
      }
    }

    .awardhistory__no-border {
      border: none;
      min-height: auto;
    }

  }

}
