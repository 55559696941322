// Search Form
.searchform {
  max-width: $sm;
  margin: 25px auto 0px auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .search-form-button {
      @media($xs-down) {
        width: 100%;
      }
    
  }
  .autocompleter {
    top: 70px;
    left: -16px;
  }
  // Input Holder
  .searchform__input {
    position: relative;
    background-color: white;
    display: flex;
    align-items: center;
    height: 60px;
    text-align: center;


    // Industry Input
    &.searchform__input--industry {
      border-radius: 10px;
      width: 100%;
      margin-bottom: 10px;

      @media($xs-up) {
        border-radius: 5px 0 0 5px;
        width: calc(50% - 50px);
        margin-bottom: 0;
      }

      &.searchform__input--industry--indent {
        text-indent: 10px;
      }

      input {
        width: calc(100% - 0px);
        height: 100%;
        font-size: 16px;
        padding-left: 15px;


        @media($sm-up) {
        }
      }
    }

    // Location Input
    &.searchform__input--location {
      width: calc(50% - 50px);

      @media($xs-up) {
        width: 40%;
      }
      @media($xs-down) {
        border-radius: 5px;
        width: 100%;
        &:before {
          display: none;
        }
      }


      input {
        width: calc(100% - 0px);
        height: 100%;
        font-size: 16px;
        padding-left: 15px;

        @media($sm-up) {
        }
      }

      // In the homepage header, at small sizes, the second field is not full width because of the submit button
      .searchform__input__container {
        height: 50px;
        display: flex;
        align-items: center;
        .header--deluxe & {
          @media($xs-down) {
            width: 100%;
          }
        }
      }
    }

    // Label
    .searchform__input__label {
      color: #094C3D;
      font-size: 18px;
      font-weight: bold;

      z-index: 1;
      width: 60px;
      height: 40px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 5px;
      margin-right: 10px;
      &:hover, &:focus {
        opacity: .5;
      }

      @media($sm-down) {
        display: none;
      }

      @media($sm-down) {
        text-indent: -9000px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30px 30px;
        &[data-icon="find"] {
          background-image: url('/static/src/img/ui-search-blue.svg');
        }
        &[data-icon="pin"] {
          background-image: url('/static/src/img/mappins/ui-mappin-empty.svg');
        }
      }
    }

    // Width restrictor
    .searchform__input__container {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: right;
      > select {
        opacity: 0;
      }
    }

  }

  // Submit button
  .searchform__submit {
    width: 90px;
    height: 40px;
    margin-left: 10px;
    @media($xs-down) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70px;
    }

    button {
      width: 100%;
      height: 100%;
      background: #C7F860;
      border: 0;
      border-radius: 0 5px 5px 0;
      background-size: 24px 24px;
      background-position: center;
      background-repeat: no-repeat;

      &:hover, &:focus {
        cursor: pointer;
      }
    }
  }

}


