/* Both normlize.css and reset.css have way to much stuff in them. This contains the useful bits and omits the rest. */

html {
  box-sizing: border-box;
  /* From Normalize.css */
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

/* Box Sizing Everywhere */
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: $sanserif;
  background: white;
  color: $color-text;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button, input, select, textarea {
  margin: 0;
}

// For IE 10-
[hidden] {
  display: none;
}
