.company-section {
  margin: 10px 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__logo-container {
    flex: 0 0 121px;
    line-height: 0;
  }

  &__logo {
    width: 100%;
  }

  &__about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 30px;
    font-weight: 300;
  }

  &__about-text-primary {
    font-size: 24px;
    color: #333;
  }

  &__about-text-secondary {
    font-size: 16px;
    color: #636463;
    margin-top: 10px;
  }

  &__link {
    color: #333;
    text-decoration: underline;

    &:hover {
      color: $color-blue-corporate;
    }

    &--secondary {
      color: #636463;
    }
  }

  &__language-container {
    font-size: 14px;
    display: flex;
    float: right;
  }
  &__language-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    margin-right: 7px;

  }
  &__language-selected {
    margin-right: 7px;
    text-decoration: underline;
  }
}

.company-footer {
  margin: 95px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: space-between;
  gap: 40px;

  &__heading {
    font-size: 20px;
    text-transform: uppercase;
    color: #707070;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    &:after {
      content: "";
      color: #D7D7D7;
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto auto auto 10px;
    }
  }

  &__section {
    flex: 1 0 337px;
    font-size: 16px;
    color: #414141;
  }

  &__link {
    color: #414141;
    text-decoration: underline;
    &:hover {
      color: $color-blue-corporate;
    }
  }
}

.card__recruiter-basic-info {
  display: flex;
  flex-flow: column nowrap;
}

.basic-info-content {
  flex: 1 1 480px;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 20px;
}


.profile-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.name-company-mobile {
  display: flex;
  flex-flow: row wrap;
  color: #343434;
  margin-left: 20px;
  text-align: left;
  align-content: flex-start;
  font-size: 14px;
  gap: 16px;
}

.name {
  flex: 1 1 100%;

  &__name {
    font-weight: 600;

    &--mobile {
      font-size: 16px;
    }
  }

  &__pronouns {
    color: #919292;
    font-size: 16px;

    &--mobile {
      font-size: 12px;
    }
  }

  &__text-segment {
    display: inline-block;
  }

  &--desktop {
    font-size: 32px;
    font-weight: 300;
    color: #343434;
  }
}

.company {
  &__name {
    margin: 18px 0 3px;
    text-transform: uppercase;

    &--mobile {
      margin: 0;
    }
  }

  &__location {
    text-transform: uppercase;
    color: #343434;
    &:hover {
      color: $color-blue-corporate;
    }
  }

  &__link {
    color: #343434;
    text-decoration: underline;
    &:hover {
      color: $color-blue-corporate;
    }
  }

  &__of {
    font-style: italic;

  }

  &--mobile {
    font-size: 12px;
  }
}

.profile-picture {
  margin: 8px 45px 0 15px;
  font-size: 18px;
  font-weight: 300;
  color: #343434;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 1 200px;

  &__wrapper {
    position: relative;
    flex: 0 0 auto;
  }

  &__badge {
    margin-top: 26px;
  }

  &__image {
    border-radius: 49.9%;
    max-width: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }
}


.cr-ratings {
  display: flex;
  flex-flow: row wrap;
  gap: 15px;

  &__ratings {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  &__text {
    margin: 0;
    font-size: 20px;
    font-weight: 300;

    &--bold {
      font-weight: 600;
    }
  }

  &__stars {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.stars__star {
  margin: 0 3px;
  text-align: center;
  padding: 4px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  background: #cccccc;
}

.stars__star--1 label {
  background: -webkit-gradient(linear, left top, right top, from(#FFA432), to(#FFA432));
  background: linear-gradient(to right, #FFA432, #FFA432 100%);
}

.stars__star--2 label {
  background: -webkit-gradient(linear, left top, right top, from(#FFA432), to(#FFA432));
  background: linear-gradient(to right, #FFA432, #FFA432 100%);
}

.stars__star--3 label {
  background: -webkit-gradient(linear, left top, right top, from(#FFA432), to(#FFA432));
  background: linear-gradient(to right, #FFA432, #FFA432 100%);
}

.stars__star--4 label {
  background: -webkit-gradient(linear, left top, right top, from(#FFA432), to(#FFA432));
  background: linear-gradient(to right, #FFA432, #FFA432 100%);
}

.stars__star--5 label {
  background: -webkit-gradient(linear, left top, right top, from(#FFA432), to(#FFA432));
  background: linear-gradient(to right, #FFA432, #FFA432 100%);
}

.stars__star label {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.stars__star:before {
  content: "";
  display: block;
  background-image: url('/static/src/img/ui-star-white.svg');
  width: 28px;
  height: 28px;
  background-size: 28px 28px;
  position: relative;
  z-index: 1;
}


.bio {
  color: #343434;

  &__heading {
    font-size: 18px;
    font-weight: 600;
  }

  &__content {
    // !important is only here to override the .card styles which have more specificity than they should
    font-size: 16px !important;
    font-weight: 300;
  }
}

.connect {
  &__heading {
    font-size: 20px;
    text-transform: uppercase;
    color: #707070;
    display: flex;
    flex-direction: row;

    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto;
      margin-left: 10px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    color: #f48d39;
    gap: 20px;
    margin-top: 20px;
  }
}

.connect-button {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  flex: 0 1 120px;
  line-height: 1;
  color: #f48d39;
  text-decoration: none;

  &:hover, &:focus {
    cursor: pointer;
  }

  &:visited {
    color: #f48d39;
  }

  &__icon {
    flex: 1 1 100%;

    &:before {
      font-size: 44px;
    }
  }

  &__text {
    flex: 1 1 auto;
    background: transparent;
    color: #f48d39;
    border: 2px solid #f48d39;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    padding: 10px;
    margin-top: 25px;
    text-decoration: none;
    text-transform: uppercase;
    width: 120px;
    height: 42px;

    &:hover {
      background-color: #f48d39;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.recommend-me {
  color: #fff;
  font-size: 34px;
  font-weight: 300;

  &__count {
    font-weight: 600;
  }
}

.connect-modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  &__content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.075);
    border: solid 1px $color-linework;
    border-radius: 3px;
    width: 344px;
  }
}

.connect-modal-header {
  display: flex;
  flex-flow: row nowrap;
  margin: auto -20px 20px;
  padding: 0 20px 16px;
  border-bottom: 1px solid #E5E5E5;

  &__name {
    color: #303030;
    font-size: 20px;
    font-weight: 600;
    margin: auto;
    flex: 1 1 auto;
  }

  &__close {
    color: #E5E5E5;
    font-size: 28px;
    font-weight: bold;
    margin-left: 20px;

    &:hover, &:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.connect-modal-body {
  text-align: center;

  &__contact-info {
    display: none; // this toggles to flex in the JS
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 14px;
  }

  &__help-text {
    color: #343434;
    font-style: italic;
    font-weight: 300;
  }

  &__contact-link {
    font-weight: 600;
    color: #1A7178;
    margin-bottom: 20px;

    &:visited {
      color: #1A7178;
    }
  }
}

.jobs-specializations {
  display: flex;
  flex-flow: column nowrap;
  gap: 35px;

  &__header {
    margin-top: 5px;
  }

  &__jobs-list {
    margin: 10px 0 0 0;
  }

  &__job-title {
    font-weight: 600;
  }

  &__job-location {
    font-weight: 300;
    margin-top: 2px;
  }
}

.specializations-list {
  column-count: 3;
  column-width: 255px;
  font-weight: 200;

  &__item {
    margin: 0 20px 5px 0;
  }

  &__item-link {
    color: #343434;

    &:hover {
      color: $color-blue-corporate;
    }
  }
}

.roles-subsection {
  display: flex;
  @media($sm-down) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  justify-content: space-between;
}

.search-jobs {
  flex: 0 0 auto;
  @media($sm-down) {
    flex: 1 1 100%;
    text-align: center;
  }
}

.search-jobs__button {
  text-align: center;
  display: inline-block;
  background: transparent;
  color: #f48d39;
  border: 2px solid #f48d39;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  padding: 10px;
  margin-top: 20px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: #f48d39;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
}

.recruiter-testimonials {
  margin-top: 20px;
}

.cr-testimonial-carousel {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 0;
}

.cr-testimonial-boxes {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
}

.cr-testimonial-box-1 {
  flex: 0 1 100%;
}

.cr-testimonial-box-2 {
  flex: 0 1 50%;
}

.cr-testimonial-box-3 {
  flex: 0 1 33.33%;
}

.triangle-arrow:hover g {
  fill: #7CAC4E;
}

button:disabled .triangle-arrow:hover g {
  fill: none;
}

button:disabled .triangle-arrow g {
  fill: none;
}

.arrow--gray g {
  fill: #CCCCCC;
}

.arrow--gray .arrow-border {
  fill: #CCCCCC;
}

button:disabled .arrow--gray:hover g {
  fill: none;
}

button:disabled .arrow--gray g {
  fill: none;
}

.arrow--gray:hover g {
  fill: #888888;
}

.cr-carousel-btn {
  height: 42px;
  width: 20px;
  border: none !important;
  background: none;
  cursor: pointer;
  margin-top: 60px;
  padding: 0;
  background-color: unset;
}

.cr-carousel-btn:after, .cr-carousel-btn:before {
  display: none;
}

.cr-text-box {
  background-color: white;
  position: relative;
  padding: 15px;
  border-radius: 3px;
  height: 200px;
}

.cr-text-box.cr-text-box__border--green {
  border: 1px solid #a1c657;
}

.cr-text-box.cr-text-box__border--gray {
  border: 1px solid #cccccc;
}

.cr-text-box:before {
  content: '';
  position: absolute;
  left: 10px;
  width: 20px;
  height: 30px;
  top: calc(100%);
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.cr-text-box.cr-text-box__border--green:before {
  background-color: #a1c657;
}

.cr-text-box.cr-text-box__border--gray:before {
  background-color: #cccccc;
}

.cr-text-box:after {
  content: '';
  position: absolute;
  left: 11px;
  width: 19px;
  height: 30px;
  background-color: #fff;
  top: calc(100% - 1px);
  clip-path: polygon(0 0, 98% 0, 0 92%);
}

.cr-scroll-content {
  height: 100%;
  overflow: auto;
}

.cr-scroll-content::-webkit-scrollbar {
  display: none;
}

.cr-scroll-content {
  scrollbar-width: none;
}

.cr-scroll-content h2 {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.cr-scroll-content p {
  padding: unset;
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}

.cr-attribution-text {
  margin: 40px 15px 0 15px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

@media($sm-down) {
  .company-section {
    &__logo-container {
      flex: 0 0 76px;
    }

    &__about-container {
      margin-left: 15px;
    }

    &__about-text-primary {
      display: none;
    }

    &__about-text-secondary {
      margin-top: 0;
      font-size: 12px;
      color: #636463;
    }
  }
  .profile-section {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .connect__buttons {
    margin-left: 35px;
    margin-right: 35px;
  }
  .connect-button__svg {
    height: 30px;
  }
  .profile-picture {
    flex-direction: row;
    flex: 1 1 auto;
    margin: 0;
    padding-bottom: 12px;

    &__wrapper {
      position: relative;
      flex: 0 0 115px;
    }
  }
  .stars__star {
    margin: 0 4px 0 0;

    &:before {
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
    }
  }
  .cr-ratings__text {
    font-size: 14px;
  }
  .connect-button__text,
  .name--desktop,
  .company--desktop {
    display: none;
  }
  .recommend-me {
    font-size: 18px;
  }
  .company-footer {
    gap: 25px;
  }
}

@media($sm-up) {
  .name-company-mobile {
    display: none;
  }
}
