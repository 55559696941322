.profilefaqs {
  .profilefaqs__collection {
    .profilefaqs__collection__single {
      margin: 0 auto;
      padding: 20px 0;
      border-bottom: solid 1px #aaaaaa;

      &:last-of-type {
        border-bottom: none;
      }

      .profilefaqs__question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 10px 0;

        p {
          font-family: "Matter", sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 46.08px;
          letter-spacing: 0%;
          color: #050505; // Black color for question text
          margin: 0;

          @media($sm-down) {
            font-size: 18px;
            line-height: 32px;
          }

          @media($xs-down) {
            font-size: 16px;
            line-height: 28px;
          }
        }

        button.faq-toggle {
          width: 29px;
          height: 29px;
          border-radius: 6.17px;
          background: #C7F860;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: light;
          color: #171717;
          cursor: pointer;
          transition: 0.3s;

          @media ($xs-down) {
            width: 24px;
            height: 24px;
            font-size: 16px;
          }

          span {
            display: block;
            transition: opacity 0.2s ease-in-out;
          }

          .minus {
            display: none; // Initially hidden
          }
        }
      }


      .profilefaqs__answer {
        display: none; // Initially hidden
        margin-top: 10px;
        font-family: "Matter", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 26.56px;
        letter-spacing: 0%;
        color: #3D3D3D; // Dark gray for answers

        @media($sm-down) {
          font-size: 14px;
          line-height: 22px;
        }

        @media($xs-down) {
          font-size: 12px;
          line-height: 20px;
        }
      }

      &.active {
        .profilefaqs__answer {
          display: block;
          font-family: "Matter", sans-serif;

          a {
            color: #094c3d;
            font-weight: 400;
            &:hover {
              text-decoration: none;
              color: #094c3d;
            }
          }
        }

        .faq-toggle {
          .plus {
            display: none;
          }

          .minus {
            display: inline-block;
          }
        }
      }
    }
  }
}
