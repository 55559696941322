// Header appears on the header of every page
.header {
  background-color: #E7F2F0;
  position: relative;
  padding-bottom: 10px;
  border-bottom: solid 1px #E7F2F0;
  font-family: Matter, Arial, sans-serif;
  background-position: center 60px;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  z-index: 100;
  width: 100%;
  @media($md-up) {
    background-position: top center;
    padding: 10px 15px 0 15px;
  }
}

// Primary content area of header (centered)
.header__primary {
  position: relative;
  z-index: 3;
  max-width: $lg;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #E7F2F0;
  width: 100%;
  height: 60px;
  user-select: none;

  @media($md-up) {
    background: transparent;
    margin: 0px auto;
  }

  // Header Logo
  a.header__primary__logo, span.header__primary__logo__private {
    width: 6.313rem;
    margin: auto;

    @media($md-up) {
      width: 6.313rem;
      margin: 5px 0 0 0;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;

      @media print {
        display: none !important;
      }
    }
  }


  .header__primary__utilitynav {
    margin-left: auto;
    margin-top: 10px;
    position: relative;
    background-color: transparent;
    @media($md-down) {
      display: none;
    }

    &:focus-within, &:hover {
      a.action--showmenu {
        color: $color-blue-corporate;
        font-weight: bold;
      }

      ul {
        display: block;
      }

      menu {
        background: white;
        border: solid 1px $color-linework;
      }
    }

    menu {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      padding: 10px;
      z-index: 5;
      width: 270px;
      border: solid 1px transparent;
    }

    a.action--showmenu {
      background: transparent;
      border: 0px;
      padding: 0;
      font-size: 14px;
      color: inherit;
      width: 100%;
      text-align: left;
      white-space: nowrap;
      margin-bottom: 8px;
      text-decoration: none;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &:hover, &:focus {
        font-weight: bold;
        color: $color-blue-dark;
      }

      svg {
        height: 1rem;
        width: 1rem;
      }
    }

    ul {
      margin: 10px auto 0 auto;
      padding: 0;
      background: white;
      width: calc(100% - 10px);
      display: none;
    }

    li {
      list-style: none;
      border-bottom: 1px solid $color-linework;

      &:last-of-type {
        border-bottom: 0;
      }

      a {
        color: $color-blue-corporate;
        text-decoration: none;
        padding: 10px 0 10px 25px;
        background-image: url(/static/src/img/ui-triangleright-blue.svg);
        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-position: 0px center;
        font-size: 14px;
        display: block;
        white-space: nowrap;

        &:hover, &:focus {
          color: $color-blue-dark;
        }
      }
    }
  }

  .header__primary__clientlogin {
    // border-left: solid 1px #AAAAAB;
    padding-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    color:#000 !important;
    text-decoration: none;
    @media($md-down) {
      display: none;
    }

    a {
      font-size: 14px;
      text-decoration: none;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }

  // Header link
  .header__primary__link {
    position: relative;
    background: #C7F860;
    margin: 10px 10px 10px 10px;
    padding: 10px 20px 10px 20px;
    text-align: center;
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.15);
    text-decoration: none;
    border-radius: 4px;
    width: 270px;
    color: #171717;
    font-size: 14px;
    line-height: 22px;
    @media($md-down) {
      display: none;
    }

  }

  // Toggle button
  .header__primary__button {
    position: absolute;
    top: 15px;
    background-color: transparent;
    border: none;
    color: white;
    display: none;
    font-size: 14px;
    padding: 10px 5px;

    &:hover, &:focus {
      text-decoration: underline;
    }

    // Cancel
    &.header__primary__button--cancel {
      left: 20px;
    }

    // Search
    &.header__primary__button--search {
      right: 20px;
    }
  }

  // Toggle for Mobile Overlay Menu
  .header__mobileoverlaytoggle {

    display: none;
    background-image: url('/static/src/img/ui-hamburger.svg');
    background-color: transparent;
    border: 0;
    width: 40px;
    height: 40px;
    text-indent: -9000px;
    position: absolute;
    top: 10px;
    right: 10px;
    top: 10px;
    right: 10px;
    @media($sm-up) {
      top: 15px;
      right: 15px;
    }
    @media($md-up) {
      display: none;
    }
  }

}

// Mobile Toggle
.header__mobiletoggle {
  padding: 0 10px;
  display: none;
  @media($xs-up) {
    display: none;
  }

  button {
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 10;
    padding: 0;
    background-color: white;
    border-radius: 10px;
    border: 0;
    display: flex;
    text-align: left;

    span {
      padding-left: 35px;
      line-height: 40px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: 5px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 11px;
      color: #999;

      &.subject {
        background-image: url('/static/src/img/ui-search-blue.svg');
        width: 60%;
      }

      &.location {
        background-image: url('/static/src/img/mappins/ui-mappin-empty.svg');
        width: 40%;
      }
    }
  }
}

// Actions
.header__actions {
  position: relative;
  z-index: 2;
  max-width: $lg;
  color: white;
  text-align: center;
  margin: 40px auto 30px auto;
  padding: 0px 10px;
  @media($xs-up) {
    padding: 0px 15px;
  }
  @media($sm-up) {
    margin: 75px auto 75px auto;
    padding: 0;
  }

  h1 {
    font-weight: 600;
    margin: 0 0 20px 0;
    font-size: 4.625rem;
    color: #000;
    line-height: normal;
    @media($sm-up) {
      font-size: 2.625rem;
    }
    @media($md-up) {
      font-size: 4.625rem;
    }
    @media($sm-down) {
      font-size: 1.625rem;
    }
  }

  p {
    font-weight: lighter;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    @media($md-up) {
      font-size: 18px;
    }
  }

}

// Deluxe header on homepage
.header--deluxe {
  @media($md-up) {
    border: none;
  }
  @media($md-down) {
    border: none;
    padding-bottom: 0;
  }
  
  .header__actions {
    top:45px;
    @media($sm-up) {
      margin: 30px auto 75px auto;
      padding: 0;
    }
  }
  .header__mobiletoggle {
    display: none;
  }
  .search-form-button {
    button {
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color: #171717;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

// Compact Variation used on subpages
.header--compact {
  background-image: none;
  background-color: #E7F2F0;
  border-bottom: 1px solid transparent;

  .header__actions {
    justify-content: center;
    display: flex;
    .searchform {
      padding: 5px 15px 5px 15px;
      align-items: center;
      .divider-line {
        border-right: 1px solid #ccc;
        height: 70%;
        @media($sm-down) {
          display: none;
        }
      }
      .searchform__input__container { 
        input {
          border: none;
        }
        input:focus {
          outline: none;
        }
        .autocompleter {
          top: 64px;
          left: -15px;
        }
      }

      .search-form-button {
        button {
          text-indent: -9000px;
          background-image: url(/static/src/img/ui-search-black.svg);
          background-size: 24px 24px;
          background-position: center;
          background-repeat: no-repeat;
          height: 3.5rem;
          width: 3.5rem;
          border-radius: 0.75rem;
          @media($xs-down) {
            text-indent: 0;
            background-image: none;
            width: 100%;
          }
        }
      }
    }
  }
  
  &:after {
    display: none;
  }

  &.header--showing {
    .header__actions {
      justify-content: center;
      display: flex;
      .searchform {
        @media($sm-down) {
          display: flex;
        }
      }
    }

    // Show Header Buttons
    .header__primary {
      @media($xs-down) {
        .header__primary__button {
          display: block;
        }
      }
    }

    // Hide mobile toggle and mobile overlay toggle
    .header__mobiletoggle, .header__mobileoverlaytoggle {
      display: none;
    }
  }

  // Compact menu is sticky on small screens, but not when height is scarce
  @media($sm-down) and (min-height: 600px) {
    position: sticky;
    top: 0;
  }

  // Compact Branding
  .header__primary {
    a.logo {
      @media($md-up) {
        margin-top: 20px;
        width: 200px;
      }
    }

    button.toggle {
      @media($xs-down) {
        display: block;
      }
    }
  }

  // Compact Actions
  .header__actions {
    margin: 0px auto;
    @media($sm-up) {
      margin: 75px auto 75px auto;
    }

    h1, p {
      display: none;
    }

    .searchform {
      margin-top: 0;
      @media($md-up) {
        margin: 0;
      }
      @media($xs-down) {
      }
    }
  }

  // Searchform
  .searchform__submit {
    @media($xs-down) {
      display: none;
    }
  }
}

.logo-print {
  display: none;
}
