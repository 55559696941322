// Card is a generic card component that holds other components, depending on the template
.card {
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  
  @media($sm-up) {
    padding: 25px;
    margin-bottom: 35px;
  }

  // Modifier: Raised Card
  &.card--raised {
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.075);
    border: 1px solid #A5A5A5;
    background-color: white;
  }

  // Modifier: Map hidden on small screens
  &.card--map{
    @media($sm-down){display: none;}
  }

  // Modifier: Single Card needs no margin-bottom
  &:last-of-type {
    margin-bottom: 0;
  }

  // Paragraph, Emphasis, Link styles are shared accross cards
  p {
    font-size: 16px;
    line-height: 1.2em;

    @media($md-up) {
      font-weight: 300;
      font-size: 20px;
    }
    em {
      font-size: 12px;
      @media($md-up) {
        font-size: 16px;
      }
    }
    a {
      color: $color-text;
      &:hover {
        color: $color-blue-corporate;
      }
    }
  }

  // Card Header used on profile page
  .card__header {
    position: relative;

    // Some card headers display a horizontal rule using --withline
    &.card__header--withline {
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        top: 50%;
        background: $color-linework;
        position: absolute;
        z-index: -1;
        width: calc(100% + 40px);
        left: -20px;
        @media($sm-up) {
          width: 100%;
          left: 0;
        }
      }
    }

    // Modified horizontal rule that only displays to the right of header text regardless of screen width
    &.card__header--right-line {
      &:after {
        width: 100%;
        left: 0;
      }
    }

    span.card__header--non-mobile {
      @media($xs-down) {
        display: none;
      }
    }

    // Card Header text
    h2{
      font-family: 'Matter', sans-serif;
      font-weight: 400;
      font-size: 22px;
      line-height: 44.8px;
      letter-spacing: -2%;
      color: #000000;

      @media($sm-down) {
        font-size: 22px;
         border-bottom: 1px solid var(--Overlays-Default, #00000033);
      }
      @media($sm-up) {
        font-size: 28px;
         border-bottom: 1px solid var(--Overlays-Default, #00000033);
      }
      @media($lg-up) {
        font-size: 32px;
        font-weight: 600;
        color: $color-text-green-dark;
        border-bottom: 1px solid var(--Overlays-Default, #00000033);
        padding: 10px;
      }
    }

    .profile-section-header {
      color: #000000;
      font-family: "Matter", sans-serif;
      font-weight: 400;
      font-size: 32px;
      line-height: 140%;
      letter-spacing: -2%;
      vertical-align: middle;
      padding: 0 0 15px 0;
    }
  }

  .card__footer {
    margin: 20px -26px -26px;
    border-radius: 0 0 3px 3px;
    padding: 20px;
    text-align: center;
    &--green {
      background-color: #198B99;
    }
    @media($sm-down) {
      margin: 20px -21px -26px;
    }
  }


  
&.card-profile-intro {

  width: 100%;
  margin: 7px auto;
  border: 1px solid #A5A5A5;

  @media ($lg-up) {
    width: 1281px;
    height: auto;
  }

  @media ($md-down) {
    width: 90%;
  }

  @media ($sm-down) {
    width: 100%;
    padding: 20px; // Add padding to maintain spacing on smaller screens
  }
}
.card-work-hire {
  width: 100%;
  height: auto;
  background: #FFFFFF;
  border-radius: 1px;

  @media ($lg-up) {
    width: 780px;
  }

  @media ($lg-down) {
    width: 100%; // Same width for medium to large screens
    margin: 30px auto;
  }

  @media ($md-down) {
    width: 90%;
    margin: 20px auto;
  }

  @media ($sm-down) {
    width: 100%;
    margin: 10px auto; // Ensures it's centered on small screens
  }

}
.card--recruiters {
  max-width: 780px; // Fixed width from Figma
  height: 353px;
  width: 100%;
  gap: 24px;
  border-radius: 6px;
  background: #FFFFFF;
  border: 1px solid #A5A5A5;

  @media ($lg-up) {
    width: 780px; // Same as `.card-getintoch`
    height: auto;
  }

  @media ($lg-down) {
    width: 780px; // Ensures it remains consistent
    height: auto;
  }

  @media ($md-down) {
    width: 90%;
    height: auto;
    padding: 24px;
    margin: 20px auto; // Center on smaller screens
  }

  @media ($sm-down) {
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 10px auto; // Center on smaller screens
  }

.card--recruiters-header {
  margin: 25px 0 25px 0;
  h2 {
    font-family: 'Matter', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 44.8px;
    letter-spacing: -2%;
    color: #000000;

    // Add responsiveness for header text
    @media($sm-up) {
      font-size: 28px;
      line-height: 40px;
    }

    @media($xs-down) {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
}
&.card-getintoch {

  height: auto;   
  width: 100%;
  padding-bottom: 20px;

  h2 {
    font-family: 'Matter', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 44.8px;
    letter-spacing: -2%;
    color: #094C3D;

    @media ($md-down) {
      font-size: 24px; 
      line-height: 40px; 
    }

    @media ($sm-down) {
      font-size: 20px;  
      line-height: 34px; 
      align-items: left;
    }
  }

  .card__header--withline {
    border-bottom: none !important;
  }

  @media ($lg-up) {
    width: 100%;
    height: auto; 
  }

  @media ($lg-down) {
    width: 100%; 
    height: auto; 
    margin: 30px auto; 
  }

  @media ($md-down) {
    width: 100%; 
    height: auto;
    margin: 20px auto; 
  }

  @media ($sm-down) {
    width: 100%; 
    height: auto; 
    margin: 10px auto; 
  }
}

.card__header-container {

  display: flex;
  justify-content: space-between; 
  align-items: center; 
  width: 100%; 
  top: 25px;
  border-bottom: 1px solid $color-linework; 
  padding-bottom: 15px;

  @media ($sm-down) { 
    flex-direction: row; 
    padding: 8px 0; 
    gap: 8px; 

    h2 {
      font-size: 20px; 
      text-align: left; 
      margin: 0; 
    }

    .button-container {
      display: flex;
      justify-content: flex-end; // Align button to the right
      flex-shrink: 0; // Prevent the button from shrinking too much
    }
  }
}

&.card__header {
  margin: 0; // Removes default margin
  font-size: 24px;
  font-weight: bold;

}

.profilecontact__linkset__single {
  // margin-top: 15px;
  width: 166px;
  height: 42px;
  a {
    font-family: 'Matter', sans-serif;
    display: block;
    width: 100%;
    height: 100%;
    background: #C7F860;
    color: #171717;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    line-height: 42px; // Centers text vertically
    font-weight: normal;

  @media ($md-down) {
    font-size: 12px;  // Reduce font size for medium screens
  }

  @media ($sm-down) {
    font-size: 10px;  // Further reduce font size for small screens
    line-height: 36px; // Adjust line-height to keep the text vertically centered
    padding: 5px;
  }
}
}

.top--margin{
  
  margin-top: 15px;
}

.card-faq {
  max-width: 780px; // Fixed width from Figma
  width: 100%;
  height: auto;
  gap: 32px;
  padding: 32px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #A5A5A5;

  .card-faq-header {
    h2 {
      font-family: 'Matter', sans-serif;
      font-weight: 400;
      font-size: 32px;
      line-height: 44.8px;
      letter-spacing: -2%;
    }
  }

  // Large screens (1200px+)
  @media ($lg-up) {
    width: 780px; // Consistency with other cards
    height: auto;
  }

  // Medium screens (992px - 1199px)
  @media ($lg-down) {
    width: 100%;
    height: auto;
    margin: 30px auto;
  }

  // Small screens (768px - 991px)
  @media ($md-down) {
    width: 90%;
    height: auto;
    margin: 20px auto;

    .card-faq-header h2 {
      font-size: 28px; // Reduce size on smaller screens
      line-height: 40px;
    }
  }

  // Extra small screens (below 768px)
  @media ($sm-down) {
    width: 100%;
    height: auto;
    margin: 0px auto;

    .card-faq-header h2 {
      font-size: 24px; // Further reduce for mobile
      line-height: 36px;
    }
  }
}

  .card-sidebar{
    @media ($md-down) {
      width: 100%;
      height: auto;
      padding: 24px;
      margin: 20px auto; // Center on smaller screens
    }
  
    @media ($sm-down) {
      width: 100%;
      height: auto;
      padding: 20px;
      margin: 0px auto; // Center on smaller screens
    }
  }
}