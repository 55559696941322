
// Modifier --showmobileoverlay:
body.body--showmobileoverlay{
	// Prevent scrolling of body under the overlay
	@media($md-down){
		height:100vh;
		overflow-y:hidden;
		// Show the overlay
		.mobileoverlay{ 
			opacity:1;
			pointer-events: all;
			transition-duration:.25s;
		}
	}
}

// Mobile Overlay
.mobileoverlay{
	background:white;
	position:absolute;
	z-index: 101;
	bottom:0;
	left:0;
	right:0;
	top:60px;
	opacity:0;
	pointer-events: none;
	transition:opacity 0s ease;

	// Mobile Overlay Navigation
	.mobileoverlay__navigation{
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		overflow-y:auto;
		padding:20px 0;
		
		ul{
			max-width:340px;
			margin:0;
			padding:20px;
		}

		li{
			list-style:none;
			margin-bottom:1.4em;
			text-align:center;
		}

		a{ 
			color:$color-blue-corporate;
			text-decoration:underline;
			font-size:16px;
			&:hover, &:focus{ text-decoration: underline; } 

			// For creating Headers
			&.bold{
				color:$color-text;
				font-size:20px;
				&:hover, &:focus{ color:$color-blue-corporate; } 
			}
			// Badge style link
			&.bubble{
				background-color:$color-orange;
				padding:8px 16px;
				border-radius:5px;
				display:inline-block;
				text-decoration:none;
				color:white;
				font-size:18px;
				&:hover, &:focus{text-decoration:underline;}
			}
		}
	}

	
}
