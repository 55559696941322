// These modifications could also go directly in the vendor css for fancybox

.fancybox-close-small{
	width:50px;
	height:50px;
	padding:0;

	&:hover, &:focus{
		background-color:$color-green-corporate;
		svg path{stroke:white;}
	}
}