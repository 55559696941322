// Alert is a component to show error messages caused by redirects/404s and searchform errors
.alert {
  background-color: $color-error;
  position: relative;
  min-height: 50px;
  display: flex;
  align-items: center;
  z-index: 10;

  // Message: area has restricted width
  .alert__message {
    width: 100%;
    max-width: $lg;
    margin: 0px auto;
    display: flex;
    align-items: center;

    // Text: is the entire width of it's parent, minus a little area for the close button
    .alert__message__text {
      padding: 5px 20px;
      font-size: 19px;
      font-size: 14px;
      color: white;
      width: calc(100% - 50px);
      color: white;
      @media($sm-up) {
        text-align: center;
      }
    }

    // Close Button
    .alert__message__close {
      width: 50px;
      position: absolute;
      right: 0;
      top: 5px;

      button {
        width: 40px;
        height: 40px;
        background-image: url('/static/src/img/ui-close-white.svg');
        background-size: 25px 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border: 0;
        text-indent: -9000px;
        cursor: pointer;
        border-radius: 5px;

        &:hover, &:focus {
          background-image: url('/static/src/img/ui-close-black.svg');
        }
      }
    }
  }

}