.map {
  background: white;
  height: 240px;
  @media($sm-up) {
    height: 400px;
  }

  &.map--small {
    height: 240px;
    margin-bottom: 10px;
  }
}