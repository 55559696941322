// Subheader Module
.subheader {

  display: flex;
  justify-content: center;
  position: relative;
  background-color: #FFFFFF;
  z-index: 0;
  -webkit-font-smoothing: antialiased;

  // Opaque Grey Mask
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: left bottom;
  }

  // Subheader Content
  .subheader__content {
    position: relative;
    z-index: 2;
    padding: 2.5vw 20px 5.5vw 20px;
    width: 100%;
    max-width: $lg;
    position: relative; 
    
    @media ($lg-up) {
      width: 1280px;
      max-width: 1444px;
      height: auto;
      padding: 80px 0 0 0;
    }

    .green_dot__wrapper {
      display: flex;
      align-items: center; 
      gap: 8px; 

    .green_dot {
      width: 12px;
      height: 12px;
      position: relative;
      border-radius: 50%;
      top: -0.7rem;
      background: var(--Colors-Green, #34C759);
    } 

    p{
      font-size: 14px;
      line-height: 21.64px;
      letter-spacing: 2%;
      text-transform: uppercase;
    }
  }

    h1 {
      font-family: 'Matter',sans-serif;
      font-weight: 400;
      font-size: 32px;
      margin: 0px 0 10px 0;
      color:  #000000;
      @media($lg-up) {
        width: 665px;
        margin: 0 0 70px 0;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -2%;
      }
      @media($lg-down) {
        font-size: 40px;
      }
    }

    p {
      margin: 0;
      font-family: 'Matter', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0%;
      padding-bottom: 20px;
      color: #171717;
      a {
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-thickness: Auto;
        text-decoration-skip-ink: auto; 
        color: #171717;
      }
    }
    #border-line{
      width: 37px;
      border-width: 4px;
      border: none;
      border-top: 4px solid  #34C759;
      @media ($lg-down) {
        display: none; // Hides border on smaller screens
      }
    }
  }

  .best-of-industry-subheader {
    border-bottom: 1px solid #d5d5d5;
  }
}