.tippy-popper[x-placement^=top] .tippy-tooltip.light-theme .tippy-arrow {
	border-top: 8px solid #fff;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme .tippy-arrow {
	border-bottom: 8px solid #fff;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme .tippy-arrow {
	border-left: 8px solid #fff;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-theme .tippy-arrow {
	border-right: 8px solid #fff;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent
}

.tippy-tooltip.light-theme {
	color:$color-text;
	font-size:12px;
	box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15);
	background-color: #fff
}

.tippy-tooltip.light-theme .tippy-backdrop {
	background-color: #fff
}

.tippy-tooltip.light-theme .tippy-roundarrow {
	fill: #fff
}

.tippy-tooltip.light-theme[data-animatefill] {
	background-color: transparent
}