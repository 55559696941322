// Profile Contact
.profilecontact {

  // Linkset appears in 2 locations
  .profilecontact__linkset {
    display: flex;
    justify-content: flex-end; // Aligns the button to the right
    align-items: flex-start; // Keeps it at the top
    position: relative;
  

    // Full set
    &.profilecontact__linkset--full {
      .profilecontact__linkset__single--claim {
        @media($md-down) {
          display: none;
        }
      }
    }

    // Claim set
    &.profilecontact__linkset--claim {
      @media($md-up) {
        display: none;
      }
    }
  }

  // Data
  .profilecontact__data {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    @media($md-up) {
      padding: 10px 0 0 0;
    }

    // Column
    .profilecontact__data__column {
      width: 100%;
      @media($md-up) {
        width: 50%;
      }

      p {
        margin: 5px 0;
        // Warning (desktop)
        font-family: 'Matter', sans-serif;
        &[data-type="brandName-address"] {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #000000;
          font-weight: bold;
          gap: 16px;

          img {
            width: 24px;
            height: 24px;
            margin-right: 8px; 
          }
          
        }
        &[data-type="address"] {
          font-size: 16px;
          color: #8E8E8E;
          margin-left: 48px;
          
        }
        &[data-type="email"] {
          text-transform: lowercase;
          hyphens: auto;
        }
        &[data-type="warning"] {
          display: none;
          margin-top: 1em;
          padding-right: 20px;
          @media($md-up) {
            display: block;
          }
        }
        // Maplink
        &[data-type="maplink"] {

          margin-left: 48px;

          /* View Map Button */
          button.profileminibio__viewmap {
            font-size: 14px;
            background-image: url('/static/src/img/ui-down-arrow.svg'); /* Set background image */
            background-size: 12px 12px;
            background-repeat: no-repeat;
            background-position: center right;
            border: none;
            background-color: transparent;
            color: #094C3D;
            font-family: 'Matter', sans-serif;
            font-weight: 400;
            text-decoration: none;
            padding-right: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 15px;
          }
        }
        &[data-type="phone"] {
          display: flex;
          align-items: center;
          color: #094C3D; 
          font-size: 16px;
          font-weight: 400;
          padding-left: 0;
        
          a {
            color: #094C3D; 
            text-decoration: none; 
            font-weight: normal; 
          }
        
          img {
            margin-right: 8px; 
          }
        }
      }
      @media ($sm-down) { 
        .profilecontact__data__column {
          width: 100%; 
          text-align: left; 
          padding: 5px 0; 
          color: red;
        }
      
        .profilecontact__data__column p {
          font-size: 12px; 
          margin-left: 10px; 
        }
      
        .profilecontact__data__column [data-type="brandName-address"] {
          font-size: 16px; 
        }
      
        .profilecontact__data__column [data-type="address"],
        .profilecontact__data__column [data-type="phone"],
        .profilecontact__data__column [data-type="email"] {
          font-size: 12px; // Optimize for smaller screens
          margin-left: 10px; // Ensure proper alignment
          padding-left: 20px;
        }
      }  
      
      .profilecontact__data__column_email{
        display: flex;
        padding-left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: -2%;
        gap: 16px;

        a{
          color: $color-text-green-dark;
        }

        img{
          margin-right: 8px;
        }
      }

      .cr-profile-contact {
        margin-top: 15px;
        padding-left: 0;
      }

      .profilecontact__data__column_phone{
        padding-left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: -2%;
        gap: 16px;

        img{
          width: 24px;
          height: 24px;
        }
      }
    }
  }

// Map Section
.profilecontact__map {
  display: none;
  &.profilecontact__map--active {
    display: block;
    padding: 10px 0;

  }
}

  // Social Icons
  .profilecontact__social_icons {

    display: flex;
    gap: 10px;
   
    @media ($md-down) {
      
      justify-content: flex-start;
      padding-left: 48px;
      margin-top: 10px;
    }
   
    img {
      width: 16px;
      height: 16px;
    }

    @media ($md-up) {

      display: grid;
      grid-template-columns: repeat(5, auto); /* Exactly 5 icons per row */
      justify-content: end;
      direction: rtl; /* Align icons from right to left */

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .social_media_data_column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;
    @media ($md-up) {

      display: flex;
      justify-content: flex-start;
      align-items: end;
    }
  }
  

  // Warning (mobile)
  .profilecontact__visitorwarning {
    @media($md-up) {
      display: none;
    }
    p {
      margin: 0;
    }
  }
}
