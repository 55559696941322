.mappopup {
  text-align: center;
  h1 {
    color: $color-blue-dark;
    margin: 0;
    font-size: 16px;
  }
  p {
    margin: 0;
    font-size: 14px;
    em {
      font-style: normal;
    }
  }

  a {
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}