/**
 * Autocompleter styles
 */

.header--deluxe{

    .searchform__input--location{
        .autocompleter{
            //outline:solid 10px red;

            &.autocompleter-show{
                &:after{
                    @media($xs-down){background-color:#0096A3;}
                }
            }
        }

    }


  
    // @media($xs-down){  width:calc(100% - 70px);  }
}


.autocompleter {
    width: 100%;
    background: #dddddd;
    background:white;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 100;
    border-radius: 0 0 10px 10px;

    &.autocompleter-show{
       &:before, &:after{
        content:"";
        display:block;
        position:absolute;
        width:10px;
        height:10px;
        background:white;
        z-index:-1;
       }
       &:before{
        top:-10px;
        left:0;
       }
       &:after{
        top:-10px;
        right:0;
        @media($sm-up){display:none;}
       }
    }
}

.autocompleter,
.autocompleter-hint {
    position: absolute;
}

.autocompleter-list {
   // box-shadow: inset 0px 0px 6px rgba(0,0,0,0.1);
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
     max-height:200px;
    overflow-y:auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 1px 3px 3px rgba(0,0,0,0.1);
    border-radius:0 0 10px 10px;
    // border-radius:10px;
}

.autocompleter-item-selected {
    background: #ffffff;
    background: #dddddd;
}

.autocompleter-item {
    padding: 6px 12px;
    padding:10px 12px 10px 20px;
    color: #444444;
    font-size: 20px;
    font-size:16px;
    cursor: pointer;

}

.autocompleter-item:hover {
    background: #dbed8a;
    background: #dddddd;

}

.autocompleter-item strong {
    background: #f9de8f;
    text-shadow: 0 1px 0 #ffffff;
}

.autocompleter-item span {
    color: #bbbbbb;
}

.autocompleter-hint {
    color: #ccc;
    text-align: left;
    top: -56px;
    font-weight: 400;
    left: 0;
    width: 100%;
    padding: 12px 12px 12px 13px;
    font-size: 24px;
    display: none;
}

.autocompleter-hint span {
    color: transparent;
}

.autocompleter-hint-show {
    display: block;
}

.autocompleter-closed {
    display: none;
}